import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {IResponse} from '../../domain/base/response'
import {TypeAccountRepository} from '../../domain/repositories/type-account.repository'
import {TypeAccountModel} from '../../domain/model/type-account.model'

@Injectable({
    providedIn: 'root',
})
export class TypeAccountImpRepository extends TypeAccountRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<TypeAccountModel[]>> {
        return this.httpClient
            .get<IResponse<TypeAccountModel[]>>(`${this.api}type-account`)
            .pipe(retry(1), catchError(this.handleError))
    }

    getBybankId(bankId: number): Observable<IResponse<TypeAccountModel[]>> {
        return this.httpClient
            .get<IResponse<TypeAccountModel[]>>(
                `${this.api}account-type-homologation/account-type/${bankId}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
