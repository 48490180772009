import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponseOk, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {TeamInfoModel, TeamModel} from 'src/app/domain/model/team.model'
import {TeamRepository} from 'src/app/domain/repositories/team.repository'
import {HeaderTeamModel} from 'src/app/domain/model/team-header.model'
import {TeamPermissionModel} from 'src/app/domain/model/team-permission.model'
import {TeamPermissionRepository} from 'src/app/domain/repositories/team-permission.repository'

@Injectable({
    providedIn: 'root',
})
export class TeamPermissionImpRepository extends TeamPermissionRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    teamInfo(teamId: number): Observable<IResponse<TeamInfoModel>> {
        return this.http.get<IResponse<TeamInfoModel>>(`${this.api}team-profile-permission/get-info/${teamId}`)
    }

    getAll(teamId: number, roleId: number): Observable<IResponse<TeamPermissionModel[]>> {
        return this.http.get<IResponse<TeamPermissionModel[]>>(`${this.api}team-profile-permission/get-all/${teamId}?roleId=${roleId}`)
    }

    update(model: any, teamId: number, key: string, value: string): Observable<IResponse<TeamPermissionModel[]>> {
        return this.http.put<IResponse<TeamPermissionModel[]>>(`${env.baseApi}team-profile-permission/update/${teamId}`, model, {
            headers: {[key]: value},
        })
    }
}
