import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {IResponse} from '../../domain/base/response'
import {ProfileRepository} from 'src/app/domain/repositories/profile.repository'
import {
    ChangeNameModel,
    ChangePasswordModel,
    ProfileModel,
} from 'src/app/domain/model/profile.model'

@Injectable({
    providedIn: 'root',
})
export class ProfileImpRepository extends ProfileRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    getInfo(): Observable<IResponse<ProfileModel>> {
        return this.http.get<IResponse<ProfileModel>>(`${env.baseApi}teams-profile/full-info`)
    }

    changePhoto(formData: FormData): Observable<IResponse<ProfileModel>> {
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/change-photo`,
            formData,
        )
    }

    removePhoto(): Observable<IResponse<ProfileModel>> {
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/remove-photo`,
            null,
        )
    }

    changeName(name: ChangeNameModel): Observable<IResponse<ProfileModel>> {
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/change-general-settings`,
            name,
        )
    }

    sendPhone(): Observable<IResponse<ProfileModel>> {
        // Send email change phone
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/change-profile/phone`,
            null,
        )
    }

    validateOTP(origin: string, code: any): Observable<IResponse<ProfileModel>> {
        const path =
            origin === 'phone' || origin === 'email'
                ? `validate/${origin}`
                : 'validate-update-phone'
        return this.http.put<IResponse<ProfileModel>>(`${env.baseApi}teams-profile/${path}`, code)
    }

    changePhoneOrEmail(path: string, data: any): Observable<IResponse<ProfileModel>> {
        return this.http.post<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/send-otp/${path}`,
            data,
        )
    }

    sendEmail(): Observable<IResponse<ProfileModel>> {
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/change-profile/email`,
            null,
        )
    }

    changePassword(data: ChangePasswordModel): Observable<IResponse<ProfileModel>> {
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/change-password`,
            data,
        )
    }

    recoverPassword(password: any, authCode: string): Observable<IResponse<ProfileModel>> {
        return this.http.put<IResponse<ProfileModel>>(
            `${env.baseApi}teams-profile/recovery-password`,
            {password},
            {headers: {'x-auth-code': authCode}},
        )
    }
}
