import {Routes} from '@angular/router'

export const FactorsRoutes: Routes = [
    {
        path: 'authentication-factors',
        loadComponent: () =>
            import('../pages/authentication-factors').then((m) => m.AuthenticationFactorsComponent),
        data: {title: 'Admin - Login'},
    },
]
