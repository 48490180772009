import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {TransactionRepository} from '../../domain/repositories/transaction.repository'
import {
    ConsolidateModel,
    FrequentModel,
    MoneyTransactionsModel,
    TransactionQueryParams,
    TransactionsModel,
} from '../../domain/model/transaction.model'

@Injectable({
    providedIn: 'root',
})
export class TransactionImpRepository extends TransactionRepository {
    readonly api = env.baseApi

    constructor(private https: HttpClient) {
        super()
    }

    getLastedTransaction(queryTransactions: TransactionQueryParams, category: string, consolidate: number = 8): Observable<TransactionsModel> {
        if (consolidate === 7) {
            return this.https.get<any>(
                `${this.api}transaction-summary/latest-transactions/${category}/${consolidate}/?fieldSearch=${queryTransactions.fieldSearch}&search=${queryTransactions.search}&page=${queryTransactions.page}&pageSize=${queryTransactions.pageSize}&fieldOrder=${queryTransactions.order}&dateFirst=${queryTransactions.dateFirst} 00:00:00&dateEnd=${queryTransactions.dateEnd} 23:59:59`,
            )
        } else {
            return this.https.get<any>(
                `${this.api}transaction-summary/latest-transactions/${category}/${consolidate}/?fieldSearch=${queryTransactions.fieldSearch}&search=${queryTransactions.search}&page=${queryTransactions.page}&pageSize=${queryTransactions.pageSize}&fieldOrder=${queryTransactions.order}`,
            )
        }
    }

    getFrequentThirds(): Observable<FrequentModel> {
        return this.https.get<any>(`${this.api}transaction-summary/frequents-thirds`)
    }

    getConsolidatedTransaction(status: string, dateFirst: string = '', dateEnd: string = ''): Observable<ConsolidateModel> {
        if (status === '7') {
            return this.https.get<any>(
                `${this.api}transaction-summary/consolidate/${status}/?dateFirst=${dateFirst} 00:00:00&dateEnd=${dateEnd} 23:59:59`,
            )
        } else {
            return this.https.get<any>(`${this.api}transaction-summary/consolidate/${status}`)
        }
    }

    getMoneyTransactions(consolidate: string): Observable<MoneyTransactionsModel> {
        return this.https.get<any>(`${this.api}transaction-summary/find-three-latest-months/${consolidate}`)
    }

    validateTransactionsExistence(): Observable<boolean> {
        return this.https.get<any>(`${this.api}transaction-summary/validate-transactions-existence`)
    }

    export(url: string): Observable<any> {
        return this.https.get<any>(`${url}`, {
            responseType: 'blob' as 'json',
        })
    }
}
