import {Mapper} from 'src/app/domain/base/mapper'
import {SendOtpModel} from 'src/app/domain/model/send-otp.model'
import {SendOtpEntity} from '../entities/send-otp.entity'

export class SendOtpMapper implements Mapper<SendOtpEntity, SendOtpModel> {
    mapFrom(param: SendOtpEntity): SendOtpModel {
        return {
            error: param.error,
            message: param.message,
            data: param.data,
        }
    }

    mapTo(param: SendOtpModel): SendOtpEntity {
        return {
            error: param.error,
            message: param.message,
            data: param.data,
        }
    }
}
