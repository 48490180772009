import {RouteInfo} from './menu.interface'

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard',
    //     title: 'Inicio',
    //     icon: 'sb-dashboard',
    //     iconActive: 'sb-dashboard-active',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: [],
    //     role: ['3'],
    //     permissions: ['view-link'],
    // },
    {
        path: '/transacciones',
        title: 'Transacciones',
        icon: 'sb-transactions',
        iconActive: 'sb-transactions-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-transactions'],
    },
    {
        path: '/pagos',
        title: 'Pagos',
        icon: 'sb-payout',
        iconActive: 'sb-payout-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '3', '8', '9'],
        permissions: ['view-transactions'],
    },
    {
        path: '',
        title: 'Cobros',
        icon: 'sb-collections',
        iconActive: 'sb-collections-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/cobrar',
                title: 'Cobrar',
                icon: 'sb-transactions',
                class: '',
                badge: '',
                badgeClass: '',
                role: ['2', '9'],
                isExternalLink: false,
                submenu: [],
                permissions: [],
            },
            {
                path: '/cobros/configurar',
                title: 'Configurar',
                icon: 'sb-transactions',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                role: ['2', '9'],
                submenu: [],
                permissions: [],
            },
        ],
        role: ['2', '9'],
        permissions: ['view-transactions'],
    },
    {
        path: '/bancos',
        title: 'Bancos',
        icon: 'bank',
        iconActive: 'bank-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-bank'],
    },
    {
        path: '/terceros',
        title: 'Terceros',
        icon: 'third',
        iconActive: 'third-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-link'],
    },
    {
        path: '/integraciones',
        title: 'Integraciones',
        icon: 'integracion',
        iconActive: 'integracion-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-link'],
    },
    {
        path: '/ajustes/general',
        title: 'Ajustes',
        icon: 'sb-setting',
        iconActive: 'sb-setting-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-setting-supplier'],
    },
]

export const SELLER_ROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'Cobros',
        icon: 'sb-collections',
        iconActive: 'sb-collections-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/cobrar',
                title: 'Cobrar',
                icon: 'sb-transactions',
                class: '',
                badge: '',
                badgeClass: '',
                role: ['2', '9'],
                isExternalLink: false,
                submenu: [],
                permissions: [],
            },
            {
                path: '/cobros/configurar',
                title: 'Configurar',
                icon: 'sb-transactions',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                role: ['2', '9'],
                submenu: [],
                permissions: [],
            },
        ],
        role: ['2'],
        permissions: ['view-transactions'],
    },
    {
        path: '/bancos',
        title: 'Bancos',
        icon: 'bank',
        iconActive: 'bank-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-bank'],
    },
    {
        path: '/terceros',
        title: 'Terceros',
        icon: 'third',
        iconActive: 'third-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-link'],
    },
    {
        path: '/ajustes/general',
        title: 'Ajustes',
        icon: 'sb-setting',
        iconActive: 'sb-setting-active',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        role: ['2', '9'],
        permissions: ['view-setting-supplier'],
    },
]
