import {Injectable, Injector} from '@angular/core'
import {NavigationEnd, NavigationExtras, NavigationStart, Router} from '@angular/router'
import {AuthRepository} from '../repositories/auth.repository'
import {StorageRepository} from '../repositories/storage.repository'
import {AuthInfoModel, AuthModel} from '../model/auth.model'
import {AuthResponse, IResponse, IResponseOk} from '../base/response'
import {EmailModel} from '../model/email.model'
import {AuthOtpModel} from '../model/auth-otp.model'
import {ToastrService} from 'ngx-toastr'
import {ChangePasswordModel} from '../model/change-password.model'
import {SendOtpModel} from '../model/send-otp.model'
import {Observable, filter, take, tap} from 'rxjs'
import {jwtDecode} from 'jwt-decode'

@Injectable({
    providedIn: 'root',
})
export class AuthUseCase {
    isAuthenticate: any
    private isLogged: boolean = false
    private timeoutDuration = 15 * 60 * 1000
    private timeout: any
    private events: string[] = ['mousemove', 'click', 'keypress', 'scroll']

    constructor(
        private repository: AuthRepository,
        private toastr: ToastrService,
        private router: Router,
        private injector: Injector,
    ) {
        this.isAuthenticate = this.getUser()
        this.resetTimeout()

        this.events.forEach((event) => {
            window.addEventListener(event, () => this.resetTimeout())
        })
    }

    login(auth: AuthModel): Observable<AuthResponse> {
        return this.repository.login(auth).pipe(
            tap((response: AuthResponse) => {
                if (response.error === 0) {
                    const storage = this.injector.get(StorageRepository)
                    localStorage.removeItem('data')
                    storage.setStorage(response.token)
                    this.isLogged = true
                    this.router.events
                        .pipe(
                            filter((event: any): event is NavigationEnd => event instanceof NavigationEnd),
                            take(1),
                        )
                        .subscribe((event: NavigationEnd) => {
                            if (event.url.includes('/pagos')) {
                                window.location.reload()
                            }
                        })

                    const user = this.getUser()
                    if (user?.twoFactorDefault === null) {
                        if (user.twoFactorApp === 'DISABLE' && user.twoFactorEmail === 'DISABLE' && user.twoFactorSms === 'DISABLE') {
                            this.router.navigate(['/authentication-factors'])
                        } else {
                            this.router.navigate(['/login'])
                        }
                    } else {
                        this.router.navigate(['/login'])
                    }
                }
            }),
        )
    }

    loginOtp(auth: AuthOtpModel) {
        const storage = this.injector.get(StorageRepository)
        return this.repository.loginOtp(auth).subscribe({
            next: (response: AuthResponse) => {
                if (response.error === 0) {
                    storage.setStorage(response.token)
                    this.isLogged = true
                    this.router.events
                        .pipe(
                            filter((event: any): event is NavigationEnd => event instanceof NavigationEnd),
                            take(1),
                        )
                        .subscribe((event: NavigationEnd) => {
                            if (event.url.includes('/pagos')) {
                                window.location.reload()
                            }
                        })
                    this.router.navigate(['/pagos'])
                } else {
                    this.toastr.error(response?.message, '', {
                        enableHtml: true,
                    })
                }
            },
        })
    }

    generateQR(): Observable<any> {
        return this.repository.generateQR()
    }

    generateOTP(digits: number = 4): Observable<any> {
        return this.repository.generateOTP(digits)
    }

    generateNewOtp(digits: number = 4, type: string): Observable<any> {
        return this.repository.generateNewOtp(digits, type)
    }

    generateNewOtpPublic(digits: number = 4, type: string, tokens: string): Observable<any> {
        return this.repository.generateNewOtpPublic(digits, type, tokens)
    }

    generateAdminOtp(digits: number = 4, type: string): Observable<any> {
        return this.repository.generateAdminOtp(digits, type)
    }

    generateDefaultOtp(): Observable<any> {
        return this.repository.generateDefaultOtp()
    }

    async setDefault2FA(type: string) {
        const storage = this.injector.get(StorageRepository)
        let default2FA: number = 0
        await new Promise<void>((resolve) =>
            this.repository.setDefault2FA(type).subscribe({
                next: (response: AuthResponse) => {
                    if (response.error === 0) {
                        localStorage.removeItem('data')
                        storage.setStorage(response.token)
                        this.isLogged = true
                        default2FA = 1
                    } else {
                        this.toastr.error(response?.message, '', {
                            enableHtml: true,
                        })
                    }
                    resolve()
                },
            }),
        )
        return default2FA
    }

    async validateOtp(status: string, authCode: string, swDisabled2FA: boolean = false): Promise<number> {
        let statusON: number = 0
        const storage = this.injector.get(StorageRepository)
        await new Promise<void>((resolve) =>
            this.repository.validateOtp(status, authCode).subscribe({
                next: (response: AuthResponse) => {
                    if (response.error === 0) {
                        statusON = 1
                        localStorage.removeItem('data')
                        storage.setStorage(response.token)
                        this.isLogged = true

                        if (swDisabled2FA) {
                            statusON = 2
                        }
                    } else {
                        this.toastr.error(response?.message, '', {
                            enableHtml: true,
                        })
                    }
                    resolve()
                },
            }),
        )

        return statusON
    }

    async validateAdminOtp(status: string, authCode: string, sw: number, swDisabled2FA: boolean = false): Promise<number> {
        let statusON: number = 0
        const storage = this.injector.get(StorageRepository)
        await new Promise<void>((resolve) =>
            this.repository.validateAdminOtp(status, authCode, sw).subscribe({
                next: (response: AuthResponse) => {
                    if (response.error === 0) {
                        localStorage.removeItem('data')
                        storage.setStorage(response.token)
                        this.isLogged = true
                        statusON = 1

                        if (swDisabled2FA) {
                            statusON = 2
                        }
                    } else {
                        this.toastr.error(response?.message, '', {
                            enableHtml: true,
                        })
                    }
                    resolve()
                },
            }),
        )

        return statusON
    }

    logout(): void {
        const storage = this.injector.get(StorageRepository)
        storage.deleteStorage()
        this.isAuthenticate = null
        this.router.navigate(['login']).then()
    }

    getInfo(): Observable<IResponse<AuthInfoModel>> {
        return this.repository.getInfo()
    }

    isAuthenticated(): boolean {
        const storage = this.injector.get(StorageRepository)
        return this.isLogged || !!storage.getStorage()
    }

    isAuthorized(roles: string[]): boolean {
        const storage = this.injector.get(StorageRepository)
        const rolesUser = storage.getFieldInToken('roles')

        let hasRoleAllowed = false
        for (let ind = 0; ind < roles.length; ind++) {
            if (rolesUser.includes(roles[ind])) {
                hasRoleAllowed = true
                break
            }
        }

        return hasRoleAllowed
    }

    get tokenAdmin(): string {
        const storage = this.injector.get(StorageRepository)
        return storage.getStorage() as string
    }

    getUser() {
        const token = this.tokenAdmin
        if (token) {
            const decode: any = jwtDecode(token)
            return {
                userId: decode.userId,
                roleId: decode.roleId,
                twoFactorApp: decode.twoFactorApp,
                twoFactorSms: decode.twoFactorSms,
                permissions: decode.permissions,
                twoFactorEmail: decode.twoFactorEmail,
                phone: decode.phone,
                email: decode.email,
                username: decode.username,
                twoFactorDefault: decode.twoFactorDefault,
                twoFactorDevice: decode.twoFactorDevice,
            }
        }
        return null
    }

    recovery(email: EmailModel) {
        return this.repository.recovery(email).subscribe({
            next: (response: IResponseOk) => {
                if (response.error === 0) {
                    this.toastr.success(response?.message, '')
                } else {
                    this.toastr.error(response?.message, '')
                }
            },
        })
    }

    sendOtp(email: EmailModel): Observable<SendOtpModel> {
        return this.repository.sendOtp(email)
    }

    disableTwoFactorDevice() {
        return this.repository.disableTwoFactorDevice().subscribe()
    }

    changePassword(change: ChangePasswordModel, key: string, value: string) {
        return this.repository.changePassword(change, key, value).subscribe({
            next: (response: IResponseOk) => {
                if (response.error === 0) {
                    this.toastr.success(response?.message, '')
                    this.router.navigate(['/login']).then()
                } else {
                    this.toastr.error(response?.message, '')
                }
            },
        })
    }

    validatePermission(permission: string): boolean {
        if (this.isAuthenticate) {
            return !!this.isAuthenticate?.permissions?.find((p: any) => p === permission)
        }
        return false
    }

    validateRole(roleId: number): boolean {
        if (this.isAuthenticate) {
            return this.isAuthenticate?.roleId === roleId
        }
        return true
    }

    systemPermission(user: any): boolean {
        let access: boolean = false

        if (user.permissions?.length) {
            user.permissions.forEach((p: any) => {
                if (!access) {
                    access = this.validatePermission(p)
                }
            })
        }

        if (user.role?.length) {
            user.role.forEach((r: any) => {
                if (!access) {
                    access = this.validateRole(r)
                }
            })
        }

        return access
    }

    removeToken() {
        const storage = this.injector.get(StorageRepository)
        storage.deleteStorage()
    }

    private resetTimeout() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.inactivityLogout(), this.timeoutDuration)
    }

    private inactivityLogout() {
        this.removeToken()
        this.router.navigate(['/login'])
    }
}
