import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {BillingRepository} from 'src/app/domain/repositories/billing.repository'
import {BillingModel} from 'src/app/domain/model/billing.model.'

@Injectable({
    providedIn: 'root',
})
export class BillingImpRepository extends BillingRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    create(model: BillingModel): Observable<IResponse<BillingModel>> {
        throw new Error('Method not implemented.')
    }
    update(model: BillingModel, id: number): Observable<IResponse<BillingModel>> {
        throw new Error('Method not implemented.')
    }

    paginate(paginate: IPaginate): Observable<IResponsePaginate<BillingModel[]>> {
        return this.http
            .get<IResponsePaginate<BillingModel[]>>(
                `${this.api}admin/billings?fieldSearch=${paginate.fieldSearch}&search=${paginate.search}&page=${paginate.page}&pageSize=${paginate.pageSize}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    export(url: string): Observable<any> {
        return this.http.get<any>(url, {
            responseType: 'blob' as 'json',
        })
    }

    // Error handling
    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
