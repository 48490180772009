import {Routes} from '@angular/router'

export const FullRecoveryRoutes: Routes = [
    {
        path: 'recovery-password',
        loadComponent: () =>
            import('../pages/user/profile/recovery-password').then(
                (m) => m.RecoveryPasswordComponent,
            ),
        data: {title: 'Admin - Recovery Password'},
    },
    {
        path: 'change-email',
        loadComponent: () =>
            import('../pages/user/profile/change-email').then((m) => m.ChangeEmailComponent),
        data: {title: 'Admin - Change Email'},
    },
]
