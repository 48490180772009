import {Injectable} from '@angular/core'
import {CookieService} from 'ngx-cookie-service'
import {StorageRepository} from '../../domain/repositories/storage.repository'
import {IPayload} from '../../core/interfaces/payload.interface'
import {jwtDecode} from 'jwt-decode'
import {environment} from 'src/environments/environment'

@Injectable({
    providedIn: 'root',
})
export class StorageImpRepository extends StorageRepository {
    nameProperty = 'token-admin'
    path = '/'

    constructor(private cookieService: CookieService) {
        super()
    }

    setStorage(value: string): void {
        this.cookieService.set(this.nameProperty, value, 0, this.path, environment.domain)
    }

    getStorage(): string | null {
        return this.cookieService.get(this.nameProperty)
    }

    deleteStorage(): void {
        this.cookieService.delete(this.nameProperty, this.path, environment.domain)
    }

    deleteAll(): void {
        this.cookieService.deleteAll(this.path, environment.domain)
    }

    getFieldInToken(fieldName: string): any {
        const tokenAdmin = this.getStorage()

        if (!tokenAdmin) {
            return null
        }
        try {
            const payload: any = jwtDecode(tokenAdmin) as IPayload
            return payload[fieldName]
        } catch (error) {}
    }
}
