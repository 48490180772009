import {Routes} from '@angular/router'
import {RouteGuard} from '../core/guard/route.guard'

export const AdminRoutes: Routes = [
    // {
    //     path: 'dashboard',
    //     loadComponent: () => import('../pages/dashboard').then(m => m.DashboardComponent),
    //     data: {title: 'Admin - Dashboard'}
    // },
    {
        path: 'pagos',
        loadComponent: () => import('../pages/transaction-payout').then((m) => m.TransactionPayoutComponent),
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'en-preparación',
            },
            {
                path: 'en-preparación',
                loadComponent: () => import('../pages/transaction-payout/payout-in-preparation').then((m) => m.PayoutInPreparationComponent),
                data: {title: 'Admin - Pagos', role: ['2', '3', '8', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'finalizados',
                loadComponent: () => import('../pages/transaction-payout/payout-finished').then((m) => m.PayoutFinishedComponent),
                data: {title: 'Admin - Pagos', role: ['2', '3', '8', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'pausados',
                loadComponent: () => import('../pages/transaction-payout/payout-paused').then((m) => m.PayoutPausedComponent),
                data: {title: 'Admin - Pagos', role: ['2', '3', '8', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'enviados',
                loadComponent: () => import('../pages/transaction-payout/payout-requested').then((m) => m.PayoutRequestedComponent),
                data: {title: 'Admin - Pagos', role: ['2', '3', '8', '9']},
                canActivate: [RouteGuard],
            },
        ],
    },
    {
        path: 'pagos/crear',
        loadComponent: () => import('../pages/payout').then((m) => m.PayoutComponent),
        data: {title: 'Admin - Crear Pago', role: ['2', '8', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'pagos/editar/:id',
        loadComponent: () => import('../pages/payout').then((m) => m.PayoutComponent),
        data: {title: 'Admin - Editar Pago', role: ['2', '8', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'pagos/ver/:id',
        loadComponent: () => import('../pages/payout').then((m) => m.PayoutComponent),
        data: {title: 'Admin - Ver Pago', role: ['2', '3', '8', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'transacciones',
        loadComponent: () => import('../pages/transaction').then((m) => m.TransactionComponent),
        data: {title: 'Admin - Ver Pago', role: ['2', '3', '8', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'transacciones/entradas-dinero',
        loadComponent: () => import('../pages/transaction/money-inflow').then((m) => m.MoneyInflowComponent),
        data: {title: 'Admin - Entradas de dinero', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'transacciones/salidas-dinero',
        loadComponent: () => import('../pages/transaction/money-outflow').then((m) => m.MoneyOutflowComponent),
        data: {title: 'Admin - Entradas de dinero', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'transacciones/todas',
        loadComponent: () => import('../pages/transaction/all-transactions').then((m) => m.AllTransactionsComponent),
        data: {title: 'Admin - Entradas de dinero', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'cobrar',
        loadComponent: () => import('../pages/transaction-collection').then((m) => m.TransactionCollectionComponent),
        data: {title: 'Admin - Cobrar'},
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/cobrar/en-preparacion',
            },
            {
                path: 'en-preparacion',
                loadComponent: () =>
                    import('../pages/transaction-collection/collection-in-preparation').then((m) => m.CollectionInPreparationComponent),
                data: {title: 'Admin - Cobrar', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'solitados',
                loadComponent: () => import('../pages/transaction-collection/collection-requested').then((m) => m.CollectionRequestedComponent),
                data: {title: 'Admin - Cobrar', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'finalizados',
                loadComponent: () => import('../pages/transaction-collection/collection-finished').then((m) => m.CollectionFinishedComponent),
                data: {title: 'Admin - Cobrar', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
        ],
    },
    {
        path: 'cobros/crear',
        loadComponent: () => import('../pages/payment').then((m) => m.PaymentComponent),
        data: {title: 'Admin - Crear Cobro', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'cobros/configurar',
        loadComponent: () => import('../pages/payment-settings').then((m) => m.PaymentSettingsComponent),
        data: {title: 'Admin - Configurar cobros', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'cobros/configurar/personalizar',
        loadComponent: () => import('../pages/payment-settings/payment-tailor').then((m) => m.PaymentTailorComponent),
        data: {title: 'Admin - Configurar cobros', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'cobrar/editar/:id',
        loadComponent: () => import('../pages/payment').then((m) => m.PaymentComponent),
        data: {title: 'Admin - Editar Cobro', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'cobrar/ver/:id',
        loadComponent: () => import('../pages/payment').then((m) => m.PaymentComponent),
        data: {title: 'Admin - Ver Cobro', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'terceros',
        loadComponent: () => import('../pages/third').then((m) => m.ThirdComponent),
        data: {title: 'Admin - Terceros'},
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'proveedores',
            },
            {
                path: 'proveedores',
                loadComponent: () => import('../pages/third/supplier').then((m) => m.SupplierComponent),
                data: {title: 'Admin - Proveedores', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'clientes',
                loadComponent: () => import('../pages/third/customer').then((m) => m.CustomerComponent),
                data: {title: 'Admin - Clientes', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'nomina',
                loadComponent: () => import('../pages/third/nomina').then((m) => m.NominaComponent),
                data: {title: 'Admin - Nómina', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
        ],
    },
    {
        path: 'terceros/proveedores/crear',
        loadComponent: () => import('../pages/third/supplier/crud').then((m) => m.CrudSupplierComponent),
        data: {title: 'Admin - Crear Proveedor', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'terceros/proveedores/editar/:id',
        loadComponent: () => import('../pages/third/supplier/crud').then((m) => m.CrudSupplierComponent),
        data: {title: 'Admin - Editar Proveedor', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'terceros/clientes/crear',
        loadComponent: () => import('../pages/third/customer/crud').then((m) => m.CrudCustomerComponent),
        data: {title: 'Admin - Crear Cliente', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'terceros/clientes/editar/:id',
        loadComponent: () => import('../pages/third/customer/crud').then((m) => m.CrudCustomerComponent),
        data: {title: 'Admin - Editar Cliente', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'bancos',
        loadComponent: () => import('../pages/bank').then((m) => m.BankComponent),
        data: {title: 'Admin - Bancos'},
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'mis-cuentas',
            },
            {
                path: 'mis-cuentas',
                loadComponent: () => import('../pages/bank/my-accounts').then((m) => m.MyAccountsComponent),
                data: {title: 'Admin - Mis cuentas', permissions: ['list-banks']},
                canActivate: [RouteGuard],
            },
        ],
    },
    {
        path: 'integraciones',
        loadComponent: () => import('../pages/integrations').then((m) => m.IntegrationsComponent),
        data: {title: 'integraciones'},
    },
    {
        path: 'integracionesConnect',
        loadComponent: () => import('../pages/integrations-connect').then((m) => m.IntegrationsConnectComponent),
        data: {title: 'integraciones conectar'},
    },
    {
        path: 'integracionesInvoices/:id',
        loadComponent: () => import('../pages/integrations-invoices').then((m) => m.IntegrationsInvoicesComponent),
        data: {title: 'integraciones facturas'},
    },
    {
        path: 'integraciones-Invoices/details/:id/:integrations_id',
        loadComponent: () => import('../pages/integrations-invoices-details').then((m) => m.IntegrationsInvoicesDetailsComponent),
        data: {title: 'integraciones facturas detalles'},
    },
    {
        path: 'bancos/mi-cuenta/:id',
        loadComponent: () => import('../pages/bank/my-accounts/my-account').then((m) => m.MyAccountComponent),
        data: {title: 'Admin - Mi cuenta', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'ajustes',
        loadComponent: () => import('../pages/setting').then((m) => m.SettingComponent),
        data: {title: 'Admin - Ajustes'},
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'general',
            },
            {
                path: 'general',
                loadComponent: () => import('../pages/setting/general').then((m) => m.GeneralComponent),
                data: {title: 'Admin - General', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'facturacion',
                loadComponent: () => import('../pages/setting/billing').then((m) => m.BillingComponent),
                data: {title: 'Admin - Facturación', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
            {
                path: 'equipo',
                loadComponent: () => import('../pages/setting/team').then((m) => m.TeamComponent),
                data: {title: 'Admin - Equipo', role: ['2', '9']},
                canActivate: [RouteGuard],
            },
        ],
    },
    {
        path: 'ajustes/equipo/editar/:id',
        loadComponent: () => import('../pages/setting/team/edit-permissions').then((m) => m.EditPermissionsComponent),
        data: {title: 'Admin - Editar Permisos Team', role: ['2', '9']},
        canActivate: [RouteGuard],
    },
    {
        path: 'user',
        loadComponent: () => import('../pages/user').then((m) => m.UserComponent),
        data: {title: 'Admin - Usuario'},
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'profile',
            },
            {
                path: 'profile',
                loadComponent: () => import('../pages/user/profile').then((m) => m.ProfileComponent),
                data: {title: 'Admin - Perfil', role: ['2', '3', '8', '9']},
                canActivate: [RouteGuard],
            },
        ],
    },
]
