<nav class="sidebar {{ toggleClass }}">
    <div class="brand-logo mb-3">
        <img src="assets/images/logo/logo.jpeg" class="logo-icon" alt="logo" width="90" />
    </div>
    <!-- <div class="custom-menu">
        <button type="button" id="sidebarCollapse" class="btn btn-primary" (click)="onToggle()">
            <img class="icon-img" width="20" height="20" [src]="iconCollapse" alt="" />
        </button>
    </div> -->
    <ul class="menu-ul">
        <li class="menu-item" *ngFor="let item of menuItems"
            [ngClass]="getLink(item.title, item.submenu) ? 'active' : ''">
            <div *ngIf="!item.submenu; then group; else default"></div>
            <ng-template #group>
                <span class="group-title ms-2">{{ item.title }}</span>
            </ng-template>
            <!-- end Group title -->

            <!-- Default item -->
            <ng-template #default>
                <div *ngIf="item.submenu.length === 0; then simple; else sub"></div>

                <!-- Single item -->
                <ng-template #simple>
                    <a [routerLink]="item.path" class="item-link ms-2" routerLinkActive="link-active"
                        (click)="toggle($event, item, this)">
                        <img [src]="getLink(item.title, item.submenu) ? routeIcon + item.iconActive + '.png' : routeIcon + item.icon + '.png'"
                            class="icons-menu" alt="" [width]="20" />
                        <span class="link-text ms-2" routerLinkActive="link-active">{{ item.title }}</span>
                    </a>
                </ng-template>
                <!-- end Single item -->

                <!-- Item with sub -->
                <ng-template #sub>
                    <a [routerLink]="item.submenu.length === 0 ? [item.path] : null" routerLinkActive="link-active"
                        class="item-link ms-2" (click)="toggle($event, item, this)">
                        <img [src]="getLink(item.title, item.submenu) ? routeIcon + item.iconActive + '.png' : routeIcon + item.icon + '.png'"
                            class="icons-menu" alt="" [width]="20" />
                        <span class="link-text ms-2"
                            [class.link-active]="getLink(item.title, item.submenu) ? 'link-active' : ''"
                            routerLinkActive="link-active">{{ item.title }}</span>
                    </a>

                    <!-- Menu sub -->
                    <ul class="sub" *ngIf="item.submenu" [@subMenu]="subState(item, isActive([item.path]))">
                        <li class="menu-item" *ngFor="let itemSub of item.submenu">
                            <a [routerLink]="itemSub.path" class="item-link ms-2" (click)="$event.preventDefault()">
                                <span class="link-text" routerLinkActive="link-active">{{ itemSub.title }}</span>
                            </a>
                        </li>
                    </ul>
                </ng-template>
                <!-- end Item with sub -->
            </ng-template>
            <!-- end Default item -->
        </li>
    </ul>

    <div class="d-flex justify-content-center">
        <div class="avatar-content">
            <div class="d-inline">
                <milio-skeleton *ngIf="viewSkeleton" appearance="circle" [theme]="{width: '3rem', height: '3rem'}"
                    animation="progress"></milio-skeleton>
                <milio-avatar *ngIf="!viewSkeleton" [image]="user.photo ? user.photo : ''" size="large" shape="circle"
                    [label]="!user.photo ? user.username.charAt(0).toUpperCase() : ''"
                    [style]="!user.photo ? {'background-color': bgAvatar, color: '#ffffff'} : ''"></milio-avatar>
            </div>
            <div class="d-inline pl-2">
                <div class="dropdown">
                    <a id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <milio-skeleton *ngIf="viewSkeleton" [theme]="{
                                width: '110px',
                                height: '40px',
                                'margin-top': '10px'
                            }" animation="progress"></milio-skeleton>
                        <h3 class="ff-satoshi-bold-500" *ngIf="!viewSkeleton">
                            {{ user.username | titlecase }}
                        </h3>
                        <h2 class="f-size-1 ff-satoshi-regular" *ngIf="!viewSkeleton">
                            {{ user.rolname | titlecase }}
                        </h2>
                    </a>
                    <ul class="dropdown-menu px-2">
                        <div class="d-flex justify-content-left align-items-center">
                            <milio-avatar *ngIf="!viewSkeleton" [image]="user ? user.photo : ''" size="large"
                                shape="circle"
                                [label]="user ? (!user.photo ? user.username.charAt(0).toUpperCase() : '') : ''"
                                [style]="
                                    user
                                        ? !user.photo
                                            ? {
                                                  'background-color': bgAvatar,
                                                  color: '#ffffff'
                                              }
                                            : ''
                                        : ''
                                "></milio-avatar>
                            <div class="ms-2">
                                <div>
                                    <span>{{ user ? (user.username | titlecase) : '' }}</span>
                                </div>
                                <div>
                                    <span>ID {{ user ? user.id : '' }}</span>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <li>
                            <a class="dropdown-item" routerLink="/user/profile">Mi cuenta</a>
                        </li>
                        <li (click)="closeSesion()">
                            <a class="dropdown-item">Cerrar sesión</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>