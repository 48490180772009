import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {DepartmentRepository} from '../../domain/repositories/department.repository'
import {IResponse} from '../../domain/base/response'
import {DepartmentModel} from '../../domain/model/department.model'

@Injectable({
    providedIn: 'root',
})
export class DepartmentImpRepository extends DepartmentRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<DepartmentModel[]>> {
        return this.httpClient.get<IResponse<DepartmentModel[]>>(`${this.api}department`)
    }

    getByCountryId(countryId: number): Observable<IResponse<DepartmentModel[]>> {
        return this.httpClient.get<IResponse<DepartmentModel[]>>(
            `${this.api}departments/${countryId}/country`,
        )
    }
}
