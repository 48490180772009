import {Injectable} from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import {AuthUseCase} from '../../domain/uses-cases/auth.use-case'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(
        private router: Router,
        private auth: AuthUseCase,
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isAuthenticated()) {
            const getUser = this.auth.getUser()
            if (!state.url.includes('authentication-factors')) {
                if (getUser?.twoFactorApp === 'DISABLE' && getUser?.twoFactorSms === 'DISABLE' && getUser?.twoFactorEmail === 'DISABLE') {
                    this.router.navigate(['authentication-factors']).then()
                    return false
                }
            } else {
                if (getUser?.twoFactorApp !== 'DISABLE' || getUser?.twoFactorSms !== 'DISABLE' || getUser?.twoFactorEmail !== 'DISABLE') {
                    this.router.navigate(['/pagos']).then()
                    return false
                }
            }
            return this.auth.isAuthenticated()
        }
        this.router.navigate(['login']).then()
        return false
    }
}
