import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {IResponse} from '../../domain/base/response'
import {CompanyPaymentMethodRepository} from 'src/app/domain/repositories/company-payment-method.repository'
import {CompanyPaymentMethodModel} from 'src/app/domain/model/company-payment-method.model'

@Injectable({
    providedIn: 'root',
})
export class CompanyPaymentMethodImpRepository extends CompanyPaymentMethodRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<CompanyPaymentMethodModel[]>> {
        return this.httpClient
            .get<IResponse<CompanyPaymentMethodModel[]>>(`${this.api}company-payment-method/all`)
            .pipe(retry(1), catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
