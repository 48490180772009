import {Observable} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../base/response'
import {SaveThirdModel, ThirdModel} from '../model/third.model'

export abstract class ThirdRepository {
    abstract create(model: SaveThirdModel, thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponse<SaveThirdModel>>
    abstract paginate(paginate: IPaginate, thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponsePaginate<ThirdModel[]>>
    abstract update(model: SaveThirdModel, id: number): Observable<IResponse<SaveThirdModel>>
    abstract export(url: string): Observable<any>
    abstract findById(id: number, thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponse<SaveThirdModel>>

    abstract search(keyvalue: string, thirdType: 'PROVEEDOR' | 'CLIENTE', thirdI?: string): Observable<IResponsePaginate<ThirdModel[]>>
}
