import {Injectable, Injector} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {IResponseOk} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {ThirdDeleteRepository} from 'src/app/domain/repositories/third-delete.repository'

@Injectable({
    providedIn: 'root',
})
export class ThirdDeleteImpRepository extends ThirdDeleteRepository {
    constructor(private injector: Injector) {
        super()
    }

    delete(id: number): Observable<IResponseOk> {
        const http = this.injector.get(HttpClient)
        return http.delete<IResponseOk>(`${env.baseApi}third/${id}`)
    }
}
