import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable, catchError, retry, throwError} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {IntegrationsRepository} from '../../domain/repositories/integrations.repository'
import {IResponse} from '../../domain/base/response'
import {
    IntegrationsModel,
    IntegrationsFieldsModel,
    IntegrationsMetadataPing,
    IntegrationsPaymentMethods,
    IntegrationsRegister,
    IntegrationsExistsModel,
    PaymentMethodApproval,
} from '../../domain/model/integrations.model'
import {IntegrationsCompanyModel} from 'src/app/domain/model/integrations-company.model'

@Injectable({
    providedIn: 'root',
})
export class IntegrationsImpRepository extends IntegrationsRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<IntegrationsModel[]>> {
        return this.httpClient.get<IResponse<IntegrationsModel[]>>(`${this.api}integrations`)
    }

    getIntegrationsCompany(id: number): Observable<IResponse<IntegrationsCompanyModel[]>> {
        return this.httpClient.get<IResponse<IntegrationsCompanyModel[]>>(`${this.api}integrations/find/by-company/${id}`)
    }

    getIntegrationsCompanys(id: number): Observable<IResponse<IntegrationsCompanyModel[]>> {
        return this.httpClient.get<IResponse<IntegrationsCompanyModel[]>>(`${this.api}integrations/find/by-company`, {
            params: {companyId: id.toString()},
        })
    }

    getByIdLoginFields(id: string): Observable<IResponse<IntegrationsFieldsModel>> {
        return this.httpClient.get<IResponse<IntegrationsFieldsModel>>(`${this.api}integrations/${id}`).pipe(retry(1), catchError(this.handleError))
    }

    exitsIntegrations(idCompany: number): Observable<IResponse<IntegrationsExistsModel>> {
        return this.httpClient.get<IResponse<IntegrationsExistsModel>>(`${this.api}integrations/company/exists`)
    }

    sendPing(data: IntegrationsMetadataPing): Observable<string> {
        const headers = new HttpHeaders().set('Accept', 'text/plain')
        return this.httpClient.post<string>(`${this.api}integrations/ping`, data, {
            headers: headers,
            responseType: 'text' as 'json',
        })
    }

    getPaymentsMethods(data: IntegrationsMetadataPing): Observable<IResponse<IntegrationsPaymentMethods>> {
        return this.httpClient.post<IResponse<IntegrationsPaymentMethods>>(`${this.api}integrations/payments-methods`, data)
    }

    getPaymentMethodApproval(idIntegrations: number): Observable<IResponse<PaymentMethodApproval>> {
        return this.httpClient.get<IResponse<PaymentMethodApproval>>(`${this.api}integrations/${idIntegrations}/payment-method-approval`)
    }

    createIntegrations(data: IntegrationsMetadataPing): Observable<IResponse<IntegrationsRegister>> {
        return this.httpClient.post<IResponse<IntegrationsRegister>>(`${this.api}integrations`, data)
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
