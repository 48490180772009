import {Injectable, Injector} from '@angular/core'
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http'
import {Router} from '@angular/router'
import {catchError, Observable, throwError} from 'rxjs'
import {ToastrService} from 'ngx-toastr'
import {AuthUseCase} from '../../domain/uses-cases/auth.use-case'
import {environment} from '../../../environments/environment'
import {StorageRepository} from 'src/app/domain/repositories/storage.repository'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private toastr: ToastrService,
        private router: Router,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authUseCase = this.injector.get(AuthUseCase)
        const token = authUseCase.tokenAdmin

        if (token) {
            request = request.clone({
                setHeaders: {Authorization: `Bearer ${token}`},
            })
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                console.log(err.status, err.error?.message)
                //token expired
                if (err.status === 401) {
                    this.toastr.error(err.error?.message, '')
                    const storage = this.injector.get(StorageRepository)
                    storage.deleteStorage()
                    //this.authService.logout();
                } else if (err.status === 409) {
                    this.toastr.error(err.error?.message, '')
                } else if (err.status === 403) {
                    this.router.navigate(['403'])
                }else if (err.status === 400) {
                    this.toastr.error(err.error?.message, '')
                } else if (err.status !== 400) {
                    this.toastr.error(err.error?.message, '')
                }

                if (!environment.production) console.log(err)
                return throwError(err)
            }),
        )
    }
}
