import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {VoucherRepository} from '../../domain/repositories/voucher.repository'
import {IResponse} from '../../domain/base/response'
import {VoucherModel} from '../../domain/model/voucher.model'

@Injectable({
    providedIn: 'root',
})
export class VoucherImpRepository extends VoucherRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<VoucherModel[]>> {
        return this.httpClient.get<IResponse<VoucherModel[]>>(`${this.api}voucher`)
    }
}
