import {Observable} from 'rxjs'
import {IResponse} from '../base/response'
import {TeamInfoModel} from '../model/team.model'
import {TeamPermissionModel} from '../model/team-permission.model'

export abstract class TeamPermissionRepository {
    abstract teamInfo(teamId: number): Observable<IResponse<TeamInfoModel>>
    abstract getAll(teamId: number, roleId: number): Observable<IResponse<TeamPermissionModel[]>>
    abstract update(model: any, teamId: number, key: string, value: string): Observable<IResponse<TeamPermissionModel[]>>
}
