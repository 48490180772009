<ng-container *ngIf="!isMobile.matches">
    <div class="milio-content">
        <div class="wrapper d-flex align-items-stretch">
            <milio-sidebar></milio-sidebar>
            <div class="wrapper-content">
                <div class="base-content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isMobile.matches">
    <div class="milio-content">
        <div class="d-flex justify-content-evenly">
            <div class="header">
                <div class="pt-2">
                    <img src="assets/images/logo/logo.jpeg" alt="logo" [width]="150">
                </div>
            </div>
            <div class="avatar-content">
                <div class="pt-4 pl-md-0 pl-lg-5">
                    <milio-skeleton *ngIf="viewSkeleton" appearance="circle" [theme]="{ width: '3rem', height: '3rem' }"
                        animation="progress"></milio-skeleton>
                    <milio-avatar *ngIf="!viewSkeleton" [image]="user.photo ? user.photo : ''" size="large"
                        shape="circle" [label]="!user.photo ? user.username.charAt(0).toUpperCase() : ''"
                        [style]="!user.photo ? {'background-color': bgAvatar, 'color':'#ffffff'} : ''"></milio-avatar>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</ng-container>