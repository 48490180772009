import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {CityRepository} from '../../domain/repositories/city.repository'
import {IResponse} from '../../domain/base/response'
import {CityModel} from '../../domain/model/city.model'

@Injectable({
    providedIn: 'root',
})
export class CityImpRepository extends CityRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<CityModel[]>> {
        return this.httpClient.get<IResponse<CityModel[]>>(`${this.api}city`)
    }

    getDepartmentById(id: number): Observable<IResponse<CityModel[]>> {
        return this.httpClient.get<IResponse<CityModel[]>>(`${this.api}cities/${id}/department`)
    }
}
