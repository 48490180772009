import {Component, OnInit} from '@angular/core'
import {CommonModule} from '@angular/common'
import {Router, RouterOutlet} from '@angular/router'

@Component({
    selector: 'full-layout',
    standalone: true,
    imports: [CommonModule, RouterOutlet],
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {
        this.isRoute403()
    }

    isRoute403(): boolean {
        return this.router.url === '/403'
    }
}
