import {Injectable} from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import {Observable} from 'rxjs'
import {AuthUseCase} from 'src/app/domain/uses-cases/auth.use-case'

@Injectable({
    providedIn: 'root',
})
export class RouteGuard  {
    constructor(
        private router: Router,
        private auth: AuthUseCase,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // console.log('🚀 ~ state:', state)
        // console.log('🚀 ~ route:', route.data)

        this.auth.isAuthenticate = this.auth.getUser()

        const access = this.auth.systemPermission(route.data)

        if (!access) {
            this.router.navigate(['403'])
        }

        return access
    }
}
