import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {ThirdRepository} from '../../domain/repositories/third.repository'
import {SaveThirdModel, ThirdModel} from '../../domain/model/third.model'

@Injectable({
    providedIn: 'root',
})
export class ThirdImpRepository extends ThirdRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    paginate(paginate: IPaginate, thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponsePaginate<ThirdModel[]>> {
        return this.http
            .get<IResponsePaginate<ThirdModel[]>>(
                `${this.api}third/${thirdType}?fieldSearch=${paginate.fieldSearch}&search=${paginate.search}&page=${paginate.page}&pageSize=${paginate.pageSize}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    export(url: string): Observable<any> {
        return this.http.get<any>(url, {
            responseType: 'blob' as 'json',
        })
    }

    create(model: SaveThirdModel, thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponse<SaveThirdModel>> {
        return this.http.post<IResponse<SaveThirdModel>>(`${env.baseApi}third/${thirdType}`, model)
    }

    update(model: SaveThirdModel, id: number): Observable<IResponse<SaveThirdModel>> {
        return this.http.put<IResponse<SaveThirdModel>>(`${env.baseApi}third/${id}`, model)
    }

    findById(id: number, thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponse<SaveThirdModel>> {
        return this.http.get<IResponse<SaveThirdModel>>(`${this.api}third/${thirdType}/${id}`).pipe(retry(1), catchError(this.handleError))
    }
    search(keyvalue: string, thirdType: 'PROVEEDOR' | 'CLIENTE', thirdId?: string): Observable<IResponsePaginate<ThirdModel[]>> {
        return this.http
            .get<IResponsePaginate<ThirdModel[]>>(
                `${this.api}third/${thirdType}/find/match?search=${thirdId ? '' : keyvalue}&thirdId=${thirdId ? thirdId : ''}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
