import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {TransactionCollectionRepository} from '../../domain/repositories/transaction-collection.repository'
import {
    IchageStatusSave,
    SaveTransactionCollectionModel,
    TransactionCollectionBadgeModel,
    TransactionCollectionHeaderModel,
    TransactionCollectionHeaderRequestedModel,
    TransactionCollectionModel,
    TransactionLaunchSdk,
} from '../../domain/model/transaction-collection.model'
import {StatusTransactionsCollection} from '../../core/general/status-transaction.enum'

@Injectable({
    providedIn: 'root',
})
export class TransactionCollectionImpRepository extends TransactionCollectionRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    badge(): Observable<IResponse<TransactionCollectionBadgeModel>> {
        return this.http
            .get<IResponse<TransactionCollectionBadgeModel>>(`${this.api}transaction-collections/summary-category`)
            .pipe(retry(1), catchError(this.handleError))
    }

    paginate(paginate: IPaginate, status: StatusTransactionsCollection): Observable<IResponsePaginate<TransactionCollectionModel[]>> {
        return this.http
            .get<
                IResponsePaginate<TransactionCollectionModel[]>
            >(`${this.api}transaction-collections/find-all/category/${status}?fieldSearch=${paginate.fieldSearch}&search=${paginate.search}&page=${paginate.page}&pageSize=${paginate.pageSize}&fieldOrder=${paginate.order}`)
            .pipe(retry(1), catchError(this.handleError))
    }

    create(model: SaveTransactionCollectionModel): Observable<IResponse<SaveTransactionCollectionModel>> {
        return this.http.post<IResponse<SaveTransactionCollectionModel>>(`${env.baseApi}transaction-payment`, model)
    }

    createObjects(model: SaveTransactionCollectionModel): Observable<IResponse<SaveTransactionCollectionModel>> {
        return this.http.post<IResponse<SaveTransactionCollectionModel>>(`${env.baseApi}transaction-payment/create-payment-obj`, model)
    }

    update(model: SaveTransactionCollectionModel, id: number): Observable<IResponse<SaveTransactionCollectionModel>> {
        return this.http.put<IResponse<SaveTransactionCollectionModel>>(`${env.baseApi}transaction-payment/${id}`, model)
    }

    disable(ids: any, password: string): Observable<IResponse<SaveTransactionCollectionModel>> {
        return this.http.put<IResponse<SaveTransactionCollectionModel>>(`${env.baseApi}transaction-collections/disable-transactions`, {
            transactionIDs: ids,
            password,
        })
    }

    findById(id: number): Observable<IResponse<TransactionCollectionModel>> {
        return this.http
            .get<IResponse<TransactionCollectionModel>>(`${this.api}transaction-payment/${id}`)
            .pipe(retry(1), catchError(this.handleError))
    }

    findFilter(model: any): Observable<IResponse<TransactionCollectionModel>> {
        return this.http.get<IResponse<TransactionCollectionModel>>(
            `${this.api}transaction-payment/filter/search/billings?voucherId=${model.voucherId}&invoiceNumber=${model.invoiceNumber}&thirdId=${model.thirdId}&transactionId=${model.transactionId}`,
        )
    }

    header(param: StatusTransactionsCollection): Observable<TransactionCollectionHeaderModel> {
        return this.http.get<any>(`${this.api}transaction-collections/consolidation/${param}`).pipe(retry(1), catchError(this.handleError))
    }

    headerRequested(): Observable<IResponse<TransactionCollectionHeaderRequestedModel>> {
        return this.http
            .get<IResponse<TransactionCollectionHeaderRequestedModel>>(`${this.api}transaction-collections/header-summary-requested`)
            .pipe(retry(1), catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }

    export(url: string): Observable<any> {
        return this.http.get<any>(`${url}`, {
            responseType: 'blob' as 'json',
        })
    }

    sendCollect(url: string, ids: any): Observable<any> {
        return this.http.put<any>(`${url}`, {transactionIDs: ids})
    }

    resendCollect(ids: any): Observable<any> {
        return this.http.put<any>(`${this.api}transaction-collections/resend-transactions`, {
            transactionIDs: ids,
        })
    }

    launchSdk(id: number) {
        return this.http.get<IResponse<TransactionLaunchSdk>>(`${this.api}transaction-collections/get-details/${id}`)
    }
}
