import {Observable} from 'rxjs'
import {ConsolidateModel, FrequentModel, MoneyTransactionsModel, TransactionQueryParams, TransactionsModel} from '../model/transaction.model'

export abstract class TransactionRepository {
    abstract getLastedTransaction(queryTransactions: TransactionQueryParams, category: string, consolidate: number): Observable<TransactionsModel>

    abstract getFrequentThirds(): Observable<FrequentModel>

    abstract getConsolidatedTransaction(status: string, dateFirst: string, dateEnd: string): Observable<ConsolidateModel>
    abstract getMoneyTransactions(consolidate: string): Observable<MoneyTransactionsModel>

    abstract validateTransactionsExistence(): Observable<boolean>

    abstract export(url: string): Observable<any>
}
