export abstract class StorageRepository {
    abstract setStorage(value: string): void

    abstract getStorage(): string | null

    abstract deleteStorage(): void

    abstract deleteAll(): void

    abstract getFieldInToken(fieldName: string): any
}
