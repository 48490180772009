import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {CompanyTeamRepository} from 'src/app/domain/repositories/company-team.repository'
import {CompanyTeamModel} from 'src/app/domain/model/company-team.model'

@Injectable({
    providedIn: 'root',
})
export class CompanyTeamImpRepository extends CompanyTeamRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
    search(keyvalue: string): Observable<IResponsePaginate<CompanyTeamModel[]>> {
        return this.http
            .get<IResponsePaginate<CompanyTeamModel[]>>(
                `${this.api}company-team/find/match?search=${keyvalue}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }
}
