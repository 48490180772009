import {Observable} from 'rxjs'
import {IResponse} from '../base/response'
import {ChangeNameModel, ChangePasswordModel, ProfileModel} from '../model/profile.model'

export abstract class ProfileRepository {
    abstract getInfo(): Observable<IResponse<ProfileModel>>
    abstract changePhoto(formData: FormData): Observable<IResponse<ProfileModel>>
    abstract removePhoto(): Observable<IResponse<ProfileModel>>
    abstract changeName(name: ChangeNameModel): Observable<IResponse<ProfileModel>>
    abstract sendPhone(): Observable<IResponse<ProfileModel>>
    abstract validateOTP(origin: string, code: any): Observable<IResponse<ProfileModel>>
    abstract changePhoneOrEmail(path: string, data: any): Observable<IResponse<ProfileModel>>
    abstract sendEmail(): Observable<IResponse<ProfileModel>>
    abstract changePassword(data: ChangePasswordModel): Observable<IResponse<ProfileModel>>
    abstract recoverPassword(password: any, authCode: string): Observable<IResponse<ProfileModel>>
}
