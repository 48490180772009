import {Observable} from 'rxjs'
import {IPaginate, IResponse, IResponseOk, IResponsePaginate} from '../base/response'
import {TeamModel} from '../model/team.model'
import {HeaderTeamModel} from '../model/team-header.model'

export abstract class TeamRepository {
    abstract header(): Observable<IResponse<HeaderTeamModel>>
    abstract getAll(): Observable<IResponse<TeamModel[]>>
    abstract create(model: TeamModel, key: string, value: string): Observable<IResponse<TeamModel>>
    abstract paginate(paginate: IPaginate): Observable<IResponsePaginate<TeamModel[]>>
    abstract export(url: string): Observable<any>
    abstract update(model: TeamModel, id: number, key: string, value: string): Observable<IResponse<TeamModel>>
    abstract delete(id: number, key: string, value: string): Observable<IResponseOk>
    abstract resendInvitation(id: number): Observable<IResponseOk>
}
