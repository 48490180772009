import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import {Observable, catchError, retry, throwError} from 'rxjs'
import {environment as env} from '../../../environments/environment'

import {IResponsePaginateInvoice, Invoice} from 'src/app/pages/integrations-invoices/models/models'
import {IntegrationsInvoiceRepository} from 'src/app/domain/repositories/integrations-invoice.repository'

@Injectable({
    providedIn: 'root',
})
export class IntegrationsInvoiceImpRepository extends IntegrationsInvoiceRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getInvoicesByCompany(
        size: number,
        start: string,
        end: string,
        paymentStatus: string,
        paymentMethod: string,
        page: number,
        integrationId: number,
    ): Observable<IResponsePaginateInvoice<Invoice[]>> {
        const headers = new HttpHeaders({})

        const params = new HttpParams()
            .set('size', size.toString())
            .set('start', start)
            .set('end', end)
            .set('payment_status', paymentStatus)
            .set('payment_method', paymentMethod)
            .set('page', page.toString())
            .set('integrationId', integrationId.toString())

        return this.httpClient
            .get<IResponsePaginateInvoice<Invoice[]>>(`${this.api}integrations/konvex/find/invoices`, {headers, params})
            .pipe(catchError(this.handleError))
    }

    getInvoicesByCompanyClient(
        client_identification: string,
        size: number,
        start: string,
        end: string,
        paymentStatus: string,
        paymentMethod: string,
        page: number,
        integrationId: number,
    ): Observable<IResponsePaginateInvoice<Invoice[]>> {
        const headers = new HttpHeaders({})

        const params = new HttpParams()
            .set('client_identification', client_identification)
            .set('size', size.toString())
            .set('start', start)
            .set('end', end)
            .set('payment_status', paymentStatus)
            .set('payment_method', paymentMethod)
            .set('page', page.toString())
            .set('integrationId', integrationId.toString())

        return this.httpClient
            .get<IResponsePaginateInvoice<Invoice[]>>(`${this.api}integrations/konvex/find/invoices`, {headers, params})
            .pipe(catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => errorMessage)
    }
}
