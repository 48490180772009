import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {IResponse} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {HeaderBillingModel} from 'src/app/domain/model/billing-header.model'
import {BillingHeaderRepository} from 'src/app/domain/repositories/billing-header.repository'

@Injectable({
    providedIn: 'root',
})
export class BillingHeaderImpRepository extends BillingHeaderRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    header(): Observable<IResponse<HeaderBillingModel>> {
        return this.http.get<IResponse<HeaderBillingModel>>(`${this.api}admin/billings/header`)
    }
}
