import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {IResponse} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {ThirdHeaderRepository} from 'src/app/domain/repositories/third-header.repository'
import {HeaderThirdModel} from 'src/app/domain/model/third-header.model'

@Injectable({
    providedIn: 'root',
})
export class ThirdHeaderImpRepository extends ThirdHeaderRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    header(thirdType: 'PROVEEDOR' | 'CLIENTE'): Observable<IResponse<HeaderThirdModel>> {
        return this.http.get<IResponse<HeaderThirdModel>>(`${this.api}third/${thirdType}/header`)
    }
}
