import {Component, Injector, OnInit} from '@angular/core'
import {CommonModule} from '@angular/common'
import {NavigationEnd, Router, RouterLinkActive, RouterLinkWithHref} from '@angular/router'
import {RouteInfo} from './menu.interface'
import {ROUTES, SELLER_ROUTES} from './menu-routes'
import {MilioAvatarModule, MilioSkeletonModule} from '@tech-milio/milio-ui'
import {StorageRepository} from 'src/app/domain/repositories/storage.repository'
import {AuthUseCase} from '../../../../domain/uses-cases/auth.use-case'
import {IResponse} from '../../../../domain/base/response'
import {AuthInfoModel} from '../../../../domain/model/auth.model'
import {animate, state, style, transition, trigger} from '@angular/animations'
import {CookieService} from 'ngx-cookie-service'
import {filter, take} from 'rxjs'
import {UpdateProfileLogoService} from 'src/app/core/services/update-profile-logo/update-profile-logo.service'

@Component({
    selector: 'milio-sidebar',
    standalone: true,
    imports: [CommonModule, RouterLinkActive, RouterLinkWithHref, MilioAvatarModule, MilioSkeletonModule],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [
        trigger('subMenu', [
            state(
                'active',
                style({
                    height: '*',
                    visibility: 'visible',
                }),
            ),
            state(
                'inactive',
                style({
                    height: 0,
                    visibility: 'hidden',
                }),
            ),
            transition('inactive => active', animate('200ms ease-in-out')),
            transition('active => inactive', animate('200ms ease-in-out')),
        ]),
    ],
})
export class SidebarComponent implements OnInit {
    toggleClass: string = ''
    iconCollapse: string = 'assets/images/icons/colapsar.svg'
    isToggle: boolean = false
    url: boolean = false
    user!: AuthInfoModel
    bgAvatar: string = '#819BFF'
    viewSkeleton: boolean = true
    viewSubmenu: boolean = false
    public menuItems!: RouteInfo[]

    routeIcon: string = 'assets/images/sidebar/'

    storage!: StorageRepository

    sellerAdmin!: boolean

    constructor(
        private router: Router,
        private injector: Injector,
        private cookieService: CookieService,
        private authUseCase: AuthUseCase,
        public updateProfileLogo: UpdateProfileLogoService,
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.viewSkeleton = false
        }, 1000)

        this.storage = this.injector.get(StorageRepository)
        this.sellerAdmin = this.storage.getFieldInToken('seller')

        this.bgAvatar = this.getBackgroundAvatar()
        this.getInfo()
        this.loadMenu()

        this.user = {
            id: '',
            username: '',
            photo: '',
            rolname: '',
        }

        this.updateProfileLogo.getLogo().subscribe((options) => {
            this.user.photo = options
        })
    }

    onToggle() {
        this.isToggle = !this.isToggle
        if (this.isToggle) {
            this.toggleClass = 'active'
            this.iconCollapse = 'assets/images/icons/expandir.svg'
        } else {
            this.toggleClass = ''
            this.iconCollapse = 'assets/images/icons/colapsar.svg'
        }
    }

    validateMenu(menus: RouteInfo[]): RouteInfo[] {
        const menuItems: RouteInfo[] = []
        for (const menu of menus) {
            if (this.authUseCase.systemPermission(menu)) {
                if (!!menu.submenu?.length) {
                    menu.submenu = this.validateMenu(menu.submenu)
                }
                menuItems.push(menu)
            }
        }
        return menuItems
    }

    loadMenu() {
        if (this.sellerAdmin) {
            this.menuItems = this.validateMenu(SELLER_ROUTES)
        } else {
            this.menuItems = this.validateMenu(ROUTES)
        }
    }

    closeSesion() {
        localStorage.clear()
        this.cookieService.delete('token-admin')
        this.router.events
            .pipe(
                filter((event: any): event is NavigationEnd => event instanceof NavigationEnd),
                take(1),
            )
            .subscribe((event: NavigationEnd) => {
                if (event.url.includes('/login')) {
                    window.location.reload()
                }
            })
        this.router.navigate(['/login'])
    }

    getInfo() {
        this.authUseCase.getInfo().subscribe({
            next: (resp: IResponse<AuthInfoModel>) => {
                if (resp.error === 0) {
                    this.user = resp.data
                }
            },
        })
    }

    getBackgroundAvatar(): string {
        const hexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F']
        const array = new Uint8Array(6)
        window.crypto.getRandomValues(array)

        let code = ''
        for (let i = 0; i < 6; i++) {
            const randomValue = array[i] % 16
            code += hexArray[randomValue]
        }
        return `#${code}`
    }

    toggle(event: Event, item: any, el: any) {
        event.preventDefault()

        let items: any[] = el.menuItems

        if (item.active) {
            item.active = false
        } else {
            for (let i = 0; i < items.length; i++) {
                items[i].active = false
            }
            item.active = true
        }
    }

    subState(item: RouteInfo, rla: boolean) {
        return item.active || rla ? 'active' : 'inactive'
    }

    closeAll() {
        this.menuItems.forEach((item: RouteInfo) => {
            item.active = false

            this.closeSub(item)
        })
    }

    closeSub(item: RouteInfo) {
        if (item.submenu && item.submenu.length) {
            item.submenu.forEach((subItem) => {
                subItem.active = false
            })
        }
    }

    isActive(instruction: any[]): boolean {
        return this.router.isActive(this.router.createUrlTree(instruction), true)
    }
    getLink(url: string, submenu: RouteInfo[]): boolean {
        if (submenu.length > 0) {
            return submenu.some((e: RouteInfo) => this.router.url.includes(e.path.toLowerCase()))
        }
        return this.router.url.includes(url.toLowerCase())
    }
}
