import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {FullLayoutComponent} from './core/components/layout/full-layout'
import {AdminLayoutComponent} from './core/components/layout/admin-layout'
import {AdminRoutes, FullRoutes} from './routes'
import {AuthGuard} from './core/guard/auth.guard'
import {FactorsRoutes} from './routes/factors.routes'
import {FactorsLayoutComponent} from './core/components/layout/factors-layout'
import {FullRecoveryRoutes} from './routes/full-recovery.routes'

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullRoutes,
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullRecoveryRoutes,
        canActivate: [AuthGuard],
    },
    {
        path: '',
        component: FactorsLayoutComponent,
        children: FactorsRoutes,
        canActivate: [AuthGuard],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: AdminRoutes,
        canActivate: [AuthGuard],
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
