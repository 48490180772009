import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {CompanyRepository} from '../../domain/repositories/company.repository'
import {environment as env} from '../../../environments/environment'
import {IResponse} from '../../domain/base/response'
import {CompanyModel} from '../../domain/model/company.model'

@Injectable({
    providedIn: 'root',
})
export class CompanyImpRepository extends CompanyRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    save(formData: FormData): Observable<IResponse<CompanyModel>> {
        return this.http.put<IResponse<CompanyModel>>(`${env.baseApi}companies`, formData)
    }

    getCompany(): Observable<IResponse<CompanyModel>> {
        return this.http.get<IResponse<CompanyModel>>(`${env.baseApi}companies`)
    }
}
