import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {TransactionPayoutRepository} from '../../domain/repositories/transaction-payout.repository'
import {
    IchageStatusSave,
    SaveTransactionPayoutModel,
    SaveUploadMassivePayoutModel,
    TransactionPayoutHeaderModel,
    TransactionPayoutModel,
    VoucherValidationModel,
} from '../../domain/model/transaction-payout.model'
import {TransactionCategory} from '../../core/general/status-transaction.enum'
import {TransactionPayoutBadgeModel} from 'src/app/domain/model/transaction-collection.model'

@Injectable({
    providedIn: 'root',
})
export class TransactionPayoutImpRepository extends TransactionPayoutRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    badge(): Observable<IResponse<TransactionPayoutBadgeModel>> {
        return this.http
            .get<IResponse<TransactionPayoutBadgeModel>>(`${this.api}transaction-payout/admin/summary-category`)
            .pipe(retry(1), catchError(this.handleError))
    }

    paginate(paginate: IPaginate, status: TransactionCategory): Observable<IResponsePaginate<TransactionPayoutModel[]>> {
        return this.http
            .get<IResponsePaginate<TransactionPayoutModel[]>>(
                `${this.api}transaction-payout/find-all/category-admin/${status}?fieldSearch=${paginate.fieldSearch}&search=${paginate.search}&page=${paginate.page}&pageSize=${paginate.pageSize}&fieldOrder=${paginate.order}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    create(model: SaveTransactionPayoutModel): Observable<IResponse<SaveTransactionPayoutModel>> {
        return this.http.post<IResponse<SaveTransactionPayoutModel>>(`${env.baseApi}transaction-payout`, model)
    }

    update(model: SaveTransactionPayoutModel, id: number): Observable<IResponse<SaveTransactionPayoutModel>> {
        return this.http.put<IResponse<SaveTransactionPayoutModel>>(`${env.baseApi}transaction-payout/${id}`, model)
    }

    findById(id: number): Observable<IResponse<TransactionPayoutModel>> {
        return this.http.get<IResponse<TransactionPayoutModel>>(`${this.api}transaction-payout/${id}`).pipe(retry(1), catchError(this.handleError))
    }

    findFilter(model: VoucherValidationModel): Observable<IResponse<TransactionPayoutModel>> {
        return this.http.get<IResponse<TransactionPayoutModel>>(
            `${this.api}transaction-payout/filter/search/billings?voucherId=${model.voucherId}&invoiceNumber=${model.invoiceNumber}&thirdId=${model.thirdId}&transactionId=${model.transactionId}`,
        )
    }

    header(param: TransactionCategory): Observable<TransactionPayoutHeaderModel> {
        return this.http
            .get<TransactionPayoutHeaderModel>(`${this.api}transaction-payout/consolidation-category/${param}`)
            .pipe(retry(1), catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }

    export(url: string): Observable<any> {
        return this.http.get<any>(`${url}`, {
            responseType: 'blob' as 'json',
        })
    }

    changeStatus(url: string, body: IchageStatusSave): Observable<any> {
        return this.http.put<any>(`${this.api}${url}`, body, {})
    }

    importGmail(): Observable<any> {
        return this.http.post<any>(`${this.api}integration/gmail/email`, null)
    }

    processFile(model: SaveUploadMassivePayoutModel): Observable<any> {
        return this.http.post<any>(`${this.api}transaction-payout/batch/bills`, model)
    }

    getExcelTemplate(): Observable<any> {
        return this.http.get<any>(`${this.api}transaction-payout/generate/template`, {
            responseType: 'blob' as 'json',
        })
    }

    importERP(): Observable<any> {
        return this.http.post<any>(`${this.api}transaction-payout/import/erp`, null)
    }
}
