import {Observable} from 'rxjs'
import {IResponse} from '../base/response'
import {IResponsePaginateInvoice, Invoice} from 'src/app/pages/integrations-invoices/models/models'

export abstract class IntegrationsInvoiceRepository {
    abstract getInvoicesByCompany(
        size: number,
        start: string,
        end: string,
        paymentStatus: string,
        paymentMethod: string,
        page: number,
        integrationId: number,
    ): Observable<IResponsePaginateInvoice<Invoice[]>>

    abstract getInvoicesByCompanyClient(
        client_identification: string,
        size: number,
        start: string,
        end: string,
        paymentStatus: string,
        paymentMethod: string,
        page: number,
        integrationId: number,
    ): Observable<IResponsePaginateInvoice<Invoice[]>>
}
