import {ChangeDetectorRef, Component, OnInit} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterOutlet} from '@angular/router'
import {MediaMatcher} from '@angular/cdk/layout'
import {SidebarComponent} from '../sidebar'
import {MilioAvatarModule, MilioSkeletonModule} from '@tech-milio/milio-ui'
import {AuthInfoModel} from 'src/app/domain/model/auth.model'
import {AuthUseCase} from 'src/app/domain/uses-cases/auth.use-case'
import {IResponse} from 'src/app/domain/base/response'

@Component({
    selector: 'app-admin-layout',
    standalone: true,
    imports: [CommonModule, SidebarComponent, RouterOutlet, MilioAvatarModule, MilioSkeletonModule],
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
    user!: AuthInfoModel
    viewSkeleton: boolean = true
    bgAvatar: string = '#819BFF'
    isMobile!: MediaQueryList
    queryListener!: () => void

    constructor(
        changeDetector: ChangeDetectorRef,
        media: MediaMatcher,
        private authUseCase: AuthUseCase,
    ) {
        this.isMobile = media.matchMedia('(max-width: 600px)')
        this.queryListener = () => changeDetector.detectChanges()
        this.isMobile.addEventListener('change', this.queryListener)
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.viewSkeleton = false
        }, 1000)
        this.bgAvatar = this.getBackgroundAvatar()
        this.getInfo()
    }
    getBackgroundAvatar(): string {
        const hexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
        const array = new Uint8Array(6);
        window.crypto.getRandomValues(array);

        let code = '';
        for (let i = 0; i < 6; i++) {
            const randomValue = array[i] % 16;
            code += hexArray[randomValue];
        }
        return `#${code}`;
    }

    getInfo() {
        this.authUseCase.getInfo().subscribe({
            next: (resp: IResponse<AuthInfoModel>) => {
                if (resp.error === 0) {
                    this.user = resp.data
                }
            },
        })
    }
}
