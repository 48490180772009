import {Component, OnInit} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterOutlet} from '@angular/router'

@Component({
    selector: 'factors-layout',
    standalone: true,
    imports: [CommonModule, RouterOutlet],
    templateUrl: './factors-layout.component.html',
    styleUrls: ['./factors-layout.component.scss'],
})
export class FactorsLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
