import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {IResponse} from '../../domain/base/response'
import {BankRepository} from '../../domain/repositories/bank.repository'
import {BankModel} from '../../domain/model/bank.model'

@Injectable({
    providedIn: 'root',
})
export class BankImpRepository extends BankRepository {
    readonly api = env.baseApi

    constructor(private httpClient: HttpClient) {
        super()
    }

    getAll(): Observable<IResponse<BankModel[]>> {
        return this.httpClient
            .get<IResponse<BankModel[]>>(`${this.api}banks`)
            .pipe(retry(1), catchError(this.handleError))
    }

    getAgreementBanksAll(): Observable<IResponse<BankModel[]>> {
        return this.httpClient
            .get<IResponse<BankModel[]>>(`${this.api}agreement/banks`)
            .pipe(retry(1), catchError(this.handleError))
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
