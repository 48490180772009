import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateProfileLogoService {

    private logo: BehaviorSubject<string> = new BehaviorSubject('');

    constructor() { }

    getLogo(): Observable<string> {
        return this.logo.asObservable();
    }

    setLogo(options: string) :void {
        this.logo.next(options);
    }

}

