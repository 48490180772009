import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router'
import {Title} from '@angular/platform-browser'
import {filter, map} from 'rxjs/operators'
import {PrimeNGConfig} from 'primeng/api'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private primeNGConfig: PrimeNGConfig,
    ) {}

    ngOnInit(): void {
        const appTitle = this.titleService.getTitle()
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let child: any = this.activatedRoute.firstChild
                    while (child.firstChild) {
                        child = child.firstChild
                    }
                    if (child.snapshot.data['title']) {
                        return child.snapshot.data['title']
                    }
                    return appTitle
                }),
            )
            .subscribe((ttl: string) => {
                this.titleService.setTitle(ttl)
            })
        this.primeNGConfig.setTranslation({
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        })
    }
}
