import {Observable} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../base/response'
import {
    IchageStatusSave,
    SaveTransactionPayoutModel,
    SaveUploadMassivePayoutModel,
    TransactionPayoutHeaderModel,
    TransactionPayoutModel,
    VoucherValidationModel,
} from '../model/transaction-payout.model'
import {TransactionCategory} from '../../core/general/status-transaction.enum'
import {TransactionPayoutBadgeModel} from '../model/transaction-collection.model'

export abstract class TransactionPayoutRepository {
    abstract badge(): Observable<IResponse<TransactionPayoutBadgeModel>>

    abstract paginate(paginate: IPaginate, status: TransactionCategory): Observable<IResponsePaginate<TransactionPayoutModel[]>>

    abstract create(model: SaveTransactionPayoutModel): Observable<IResponse<SaveTransactionPayoutModel>>

    abstract update(model: SaveTransactionPayoutModel, id: number): Observable<IResponse<SaveTransactionPayoutModel>>

    abstract findById(id: number): Observable<IResponse<TransactionPayoutModel>>

    abstract findFilter(model: VoucherValidationModel): Observable<IResponse<TransactionPayoutModel>>

    abstract header(param: TransactionCategory): Observable<TransactionPayoutHeaderModel>

    abstract export(url: string): Observable<any>

    abstract changeStatus(url: string, body: IchageStatusSave): Observable<any>

    abstract importGmail(): Observable<any>

    abstract processFile(model: SaveUploadMassivePayoutModel): Observable<any>

    abstract getExcelTemplate(): Observable<any>

    abstract importERP(): Observable<any>
}
