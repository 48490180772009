import {Observable} from 'rxjs'
import {AuthInfoModel, AuthModel} from '../model/auth.model'
import {AuthResponse, IResponse, IResponseOk} from '../base/response'
import {EmailModel} from '../model/email.model'
import {AuthOtpModel} from '../model/auth-otp.model'
import {ChangePasswordModel} from '../model/change-password.model'
import {SendOtpModel} from '../model/send-otp.model'

export abstract class AuthRepository {
    abstract login(auth: AuthModel): Observable<AuthResponse>
    abstract recovery(email: EmailModel): Observable<IResponseOk>
    abstract sendOtp(email: EmailModel): Observable<SendOtpModel>
    abstract loginOtp(auth: AuthOtpModel): Observable<AuthResponse>
    abstract changePassword(change: ChangePasswordModel, key: string, value: string): Observable<IResponseOk>
    abstract getInfo(): Observable<IResponse<AuthInfoModel>>
    abstract generateQR(): Observable<any>
    abstract generateOTP(digits: number): Observable<any>
    abstract generateNewOtp(digits: number, type: string): Observable<any>
    abstract generateNewOtpPublic(digits: number, type: string, tokens: string): Observable<any>
    abstract generateAdminOtp(digits: number, type: string): Observable<any>
    abstract validateOtp(status: string, authCode: string): Observable<AuthResponse>
    abstract validateAdminOtp(status: string, authCode: string, sw: number): Observable<AuthResponse>
    abstract setDefault2FA(type: string): Observable<any>
    abstract disableTwoFactorDevice(): Observable<any>

    abstract generateDefaultOtp(): Observable<any>
}
