import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponseOk, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {TeamModel} from 'src/app/domain/model/team.model'
import {TeamRepository} from 'src/app/domain/repositories/team.repository'
import {HeaderTeamModel} from 'src/app/domain/model/team-header.model'

@Injectable({
    providedIn: 'root',
})
export class TeamImpRepository extends TeamRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    header(): Observable<IResponse<HeaderTeamModel>> {
        return this.http.get<IResponse<HeaderTeamModel>>(`${this.api}teams/admin/header`)
    }

    getAll(): Observable<IResponse<TeamModel[]>> {
        //TODO organizar pagination
        return this.http.get<IResponse<TeamModel[]>>(`${this.api}teams?fieldSearch=all&search=&page=1&pageSize=1000`)
    }
    paginate(paginate: IPaginate): Observable<IResponsePaginate<TeamModel[]>> {
        return this.http
            .get<IResponsePaginate<TeamModel[]>>(
                `${this.api}teams?fieldSearch=${paginate.fieldSearch}&search=${paginate.search}&page=${paginate.page}&pageSize=${paginate.pageSize}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    export(url: string): Observable<any> {
        return this.http.get<any>(url, {
            responseType: 'blob' as 'json',
        })
    }

    create(model: TeamModel, key: string, value: string): Observable<IResponse<TeamModel>> {
        return this.http.post<IResponse<TeamModel>>(`${env.baseApi}teams`, model, {headers: {[key]: value}})
    }

    update(model: TeamModel, id: number, key: string, value: string): Observable<IResponse<TeamModel>> {
        return this.http.put<IResponse<TeamModel>>(`${env.baseApi}teams/${id}`, model, {headers: {[key]: value}})
    }

    delete(id: number, key: string, value: string): Observable<IResponseOk> {
        return this.http.delete<IResponseOk>(`${env.baseApi}teams/${id}`, {headers: {[key]: value}})
    }

    resendInvitation(id: number): Observable<IResponseOk> {
        return this.http.put<IResponseOk>(`${env.baseApi}teams/resend-invitation/${id}`, null)
    }

    // Error handling
    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }
}
