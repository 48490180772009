import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {BrowserModule} from '@angular/platform-browser'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {ToastrModule} from 'ngx-toastr'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'

import {TokenInterceptor} from './core/interceptors/token.interceptor'
import {AuthRepository} from './domain/repositories/auth.repository'
import {AuthImpRepository} from './infrastructure/implementation/auth.imp.repository'
import {AuthUseCase} from './domain/uses-cases/auth.use-case'
import {StorageRepository} from './domain/repositories/storage.repository'
import {StorageImpRepository} from './infrastructure/implementation/storage.imp.repository'
import {ThirdImpRepository} from './infrastructure/implementation/third.imp.repository'
import {ThirdRepository} from './domain/repositories/third.repository'
import {TypeDocumentRepository} from './domain/repositories/type-document.repository'
import {TypeDocumentImpRepository} from './infrastructure/implementation/type-document.imp.repository'
import {ThirdCategoryImpRepository} from './infrastructure/implementation/third-category.imp.repository'
import {ThirdCategoryRepository} from './domain/repositories/third-category.repository'
import {CountryImpRepository} from './infrastructure/implementation/country.imp.repository'
import {CountryRepository} from './domain/repositories/country.repository'
import {BankRepository} from './domain/repositories/bank.repository'
import {BankImpRepository} from './infrastructure/implementation/bank.imp.repository'
import {TypeAccountImpRepository} from './infrastructure/implementation/type-account.imp.repository'
import {TypeAccountRepository} from './domain/repositories/type-account.repository'
import {CompanyRepository} from './domain/repositories/company.repository'
import {CompanyImpRepository} from './infrastructure/implementation/company.imp.repository'
import {CityRepository} from './domain/repositories/city.repository'
import {CityImpRepository} from './infrastructure/implementation/city.imp.repository'
import {DepartmentRepository} from './domain/repositories/department.repository'
import {DepartmentImpRepository} from './infrastructure/implementation/department.imp.repository'
import {TeamRepository} from './domain/repositories/team.repository'
import {TeamImpRepository} from './infrastructure/implementation/team.imp.repository'
import {RoleRepository} from './domain/repositories/role.repository'
import {RoleImpRepository} from './infrastructure/implementation/role.imp.repository'
import {MilioSpinnerInterceptor, SpinnerModule} from '@tech-milio/milio-ui'
import {TransactionPayoutImpRepository} from './infrastructure/implementation/transaction-payout.imp.repository'
import {TransactionPayoutRepository} from './domain/repositories/transaction-payout.repository'
import {TransactionRepository} from './domain/repositories/transaction.repository'
import {TransactionImpRepository} from './infrastructure/implementation/transaction.imp.repoitory'
import {VoucherRepository} from './domain/repositories/voucher.repository'
import {VoucherImpRepository} from './infrastructure/implementation/voucher.imp.repository'

import {ThirdHeaderRepository} from './domain/repositories/third-header.repository'
import {ThirdHeaderImpRepository} from './infrastructure/implementation/third-header.imp.repository'
import {ThirdDeleteRepository} from './domain/repositories/third-delete.repository'
import {ThirdDeleteImpRepository} from './infrastructure/implementation/third-delete.imp.repository'
import {BillingHeaderRepository} from './domain/repositories/billing-header.repository'
import {BillingHeaderImpRepository} from './infrastructure/implementation/billing-header.imp.repository'
import {BillingRepository} from './domain/repositories/billing.repository'
import {BillingImpRepository} from './infrastructure/implementation/billing.imp.repository'
import {CompanyTeamImpRepository} from './infrastructure/implementation/company-team.imp.repository'
import {CompanyTeamRepository} from './domain/repositories/company-team.repository'
import {CompanyBankImpRepository} from './infrastructure/implementation/company-bank.imp.repository'
import {CompanyBankRepository} from './domain/repositories/company-bank.repository'
import {TransactionCollectionImpRepository} from './infrastructure/implementation/transaction-collection.imp.repository'
import {TransactionCollectionRepository} from './domain/repositories/transaction-collection.repository'
import {CompanyPaymentMethodRepository} from './domain/repositories/company-payment-method.repository'
import {CompanyPaymentMethodImpRepository} from './infrastructure/implementation/company-payment-method.imp.repository'
import {CompanyPaymentSettingRepository} from './domain/repositories/company-payment-setting.repository'
import {CompanyPaymentSettingImpRepository} from './infrastructure/implementation/company-payment-setting.imp.repository'
import {ProfileRepository} from './domain/repositories/profile.repository'
import {ProfileImpRepository} from './infrastructure/implementation/profile.imp.repository'
import {LY_THEME, LY_THEME_NAME, LyCommonModule, LyTheme2, StyleRenderer} from '@alyle/ui'
import {MinimaLight, MinimaDeepDark, MinimaDark} from '@alyle/ui/themes/minima'
import {TeamPermissionImpRepository} from './infrastructure/implementation/team-permission.imp.repository'
import {TeamPermissionRepository} from './domain/repositories/team-permission.repository'

import {IntegrationsRepository} from './domain/repositories/integrations.repository'
import {IntegrationsImpRepository} from './infrastructure/implementation/integrations.imp.repository'
import {IntegrationsInvoiceImpRepository} from './infrastructure/implementation/integrations-invoice.imp.repository'
import {IntegrationsInvoiceRepository} from './domain/repositories/integrations-invoice.repository'

let interceptorOptions = {
    exclude: [
        {
            url: `company-payment-setting`,
            methods: ['put'],
        },
        {
            url: `integration/gmail/email`,
            methods: ['post'],
        },
        {
            url: `transaction-payout/import/massive`,
            methods: ['post'],
        },
        {
            url: `transaction-payout/admin/update/status-to-payed`,
            methods: ['post'],
        },
        {
            url: `/third/PROVEEDOR/find/match`,
            methods: ['get'],
        },
        {
            url: `/third/CLIENTE/find/match`,
            methods: ['get'],
        },
        {
            url: `/transaction-payout/batch/bills`,
            methods: ['post'],
        },
        {
            url: `/transaction-payment/filter/search`,
            methods: ['get'],
        },
    ],
}
@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent],
    imports: [BrowserAnimationsModule, BrowserModule, AppRoutingModule, HttpClientModule, ToastrModule.forRoot(), SpinnerModule, LyCommonModule],
    providers: [
        {provide: AuthRepository, useClass: AuthImpRepository},
        {provide: StorageRepository, useClass: StorageImpRepository},
        {provide: TypeDocumentRepository, useClass: TypeDocumentImpRepository},
        {provide: ThirdCategoryRepository, useClass: ThirdCategoryImpRepository},
        {provide: CountryRepository, useClass: CountryImpRepository},
        {provide: CityRepository, useClass: CityImpRepository},
        {provide: DepartmentRepository, useClass: DepartmentImpRepository},
        {provide: BankRepository, useClass: BankImpRepository},
        {provide: TypeAccountRepository, useClass: TypeAccountImpRepository},
        {provide: RoleRepository, useClass: RoleImpRepository},

        {provide: ThirdRepository, useClass: ThirdImpRepository},
        {provide: ThirdHeaderRepository, useClass: ThirdHeaderImpRepository},
        {provide: ThirdDeleteRepository, useClass: ThirdDeleteImpRepository},

        {provide: CompanyRepository, useClass: CompanyImpRepository},
        {provide: CompanyPaymentMethodRepository, useClass: CompanyPaymentMethodImpRepository},
        {provide: CompanyPaymentSettingRepository, useClass: CompanyPaymentSettingImpRepository},
        {provide: CompanyBankRepository, useClass: CompanyBankImpRepository},
        {provide: CompanyTeamRepository, useClass: CompanyTeamImpRepository},

        {provide: TeamRepository, useClass: TeamImpRepository},

        {provide: BillingRepository, useClass: BillingImpRepository},
        {provide: BillingHeaderRepository, useClass: BillingHeaderImpRepository},

        {provide: TransactionPayoutRepository, useClass: TransactionPayoutImpRepository},
        {provide: TransactionRepository, useClass: TransactionImpRepository},
        {provide: TransactionCollectionRepository, useClass: TransactionCollectionImpRepository},
        {provide: VoucherRepository, useClass: VoucherImpRepository},
        {provide: ProfileRepository, useClass: ProfileImpRepository},
        {provide: TeamPermissionRepository, useClass: TeamPermissionImpRepository},
        {provide: IntegrationsRepository, useClass: IntegrationsImpRepository},
        {provide: IntegrationsInvoiceRepository, useClass: IntegrationsInvoiceImpRepository},

        [StyleRenderer],
        [LyTheme2],
        {provide: LY_THEME_NAME, useValue: 'minima-light'},
        {provide: LY_THEME, useClass: MinimaLight, multi: true},
        {provide: LY_THEME, useClass: MinimaDeepDark, multi: true},
        {provide: LY_THEME, useClass: MinimaDark, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: MilioSpinnerInterceptor, multi: true},
        {
            provide: 'config',
            useValue: interceptorOptions,
        },
        AuthUseCase,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
