import {Observable} from 'rxjs'
import {IResponse} from '../base/response'
import {
    IntegrationsModel,
    IntegrationsFieldsModel,
    IntegrationsMetadataPing,
    IntegrationsPaymentMethods,
    IntegrationsRegister,
    IntegrationsExistsModel,
    PaymentMethodApproval,
} from '../model/integrations.model'
import {IntegrationsCompanyModel} from '../model/integrations-company.model'

export abstract class IntegrationsRepository {
    abstract getAll(): Observable<IResponse<IntegrationsModel[]>>
    abstract getIntegrationsCompany(id: number): Observable<IResponse<IntegrationsCompanyModel[]>>
    abstract getIntegrationsCompanys(id: number): Observable<IResponse<IntegrationsCompanyModel[]>>
    abstract exitsIntegrations(id: number): Observable<IResponse<IntegrationsExistsModel>>
    abstract getByIdLoginFields(id: string): Observable<IResponse<IntegrationsFieldsModel>>
    abstract sendPing(data: IntegrationsMetadataPing): Observable<string>
    abstract getPaymentsMethods(data: IntegrationsMetadataPing): Observable<IResponse<IntegrationsPaymentMethods>>
    abstract getPaymentMethodApproval(id: number): Observable<IResponse<PaymentMethodApproval>>
    abstract createIntegrations(data: IntegrationsMetadataPing): Observable<IResponse<IntegrationsRegister>>
}
