import {Observable} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../base/response'
import {
    IchageStatusSave,
    SaveTransactionCollectionModel,
    TransactionCollectionBadgeModel as TransactionCollectionBadgeModel,
    TransactionCollectionHeaderModel,
    TransactionCollectionHeaderRequestedModel,
    TransactionCollectionModel,
} from '../model/transaction-collection.model'
import {StatusTransactionsCollection} from '../../core/general/status-transaction.enum'

export abstract class TransactionCollectionRepository {
    abstract badge(): Observable<IResponse<TransactionCollectionBadgeModel>>

    abstract paginate(paginate: IPaginate, status: StatusTransactionsCollection): Observable<IResponsePaginate<TransactionCollectionModel[]>>

    abstract create(model: SaveTransactionCollectionModel): Observable<IResponse<SaveTransactionCollectionModel>>

    abstract createObjects(model: SaveTransactionCollectionModel): Observable<IResponse<SaveTransactionCollectionModel>>

    abstract update(model: SaveTransactionCollectionModel, id: number): Observable<IResponse<SaveTransactionCollectionModel>>

    abstract disable(ids: any, password: string): Observable<IResponse<SaveTransactionCollectionModel>>

    abstract findFilter(model: TransactionCollectionModel): Observable<IResponse<TransactionCollectionModel>>

    abstract findById(id: number): Observable<IResponse<TransactionCollectionModel>>

    abstract header(param: StatusTransactionsCollection): Observable<TransactionCollectionHeaderModel>

    abstract headerRequested(): Observable<IResponse<TransactionCollectionHeaderRequestedModel>>

    abstract export(url: string): Observable<any>

    abstract sendCollect(url: string, ids: any): Observable<any>

    abstract resendCollect(ids: any): Observable<any>

    abstract launchSdk(id: number): Observable<any>
}
