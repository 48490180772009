import {Injectable, Injector} from '@angular/core'
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import {Observable, of} from 'rxjs'
import {catchError, map} from 'rxjs/operators'
import {AuthRepository} from '../../domain/repositories/auth.repository'
import {AuthInfoModel, AuthModel} from '../../domain/model/auth.model'
import {AuthResponse, IResponse, IResponseOk} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {EmailModel} from 'src/app/domain/model/email.model'
import {AuthOtpModel} from 'src/app/domain/model/auth-otp.model'
import {ChangePasswordModel} from 'src/app/domain/model/change-password.model'
import {SendOtpModel} from 'src/app/domain/model/send-otp.model'
import {SendOtpMapper} from '../mappers/send-otp.mapper'
import {SendOtpEntity} from '../entities/send-otp.entity'

@Injectable({
    providedIn: 'root',
})
export class AuthImpRepository extends AuthRepository {
    private mapper = new SendOtpMapper()

    constructor(private injector: Injector) {
        super()
    }

    login(auth: AuthModel): Observable<AuthResponse> {
        const https = this.injector.get(HttpClient)
        return https.post<AuthResponse>(`${env.baseApi}teams/login`, auth)
    }

    generateQR(): Observable<any> {
        const https = this.injector.get(HttpClient)
        return https.post(`${env.baseApi}two-factor/activate-app-authenticator`, {}, {responseType: 'blob', observe: 'response'})
    }

    generateOTP(digits: any = 4): Observable<any> {
        const https = this.injector.get(HttpClient)
        return https.post(`${env.baseApi}two-factor-sms/activate-sms-authenticator`, {digits: digits})
    }

    generateNewOtp(digits: any = 4, type: string): Observable<any> {
        if (type === 'APP') {
            const https = this.injector.get(HttpClient)
            return https.post(`${env.baseApi}two-factor/generate-otp`, {digits, type}, {responseType: 'blob', observe: 'response'})
        } else {
            const https = this.injector.get(HttpClient)
            return https.post(`${env.baseApi}two-factor/generate-otp`, {digits, type})
        }
    }

    generateAdminOtp(digits: any = 4, type: string): Observable<any> {
        if (type === 'APP') {
            const https = this.injector.get(HttpClient)
            return https.post(`${env.baseApi}two-factor/admin/generate-otp`, {digits, type}, {responseType: 'blob', observe: 'response'})
        } else {
            const https = this.injector.get(HttpClient)
            return https.post(`${env.baseApi}two-factor/admin/generate-otp`, {digits, type})
        }
    }

    generateDefaultOtp(): Observable<any> {
        const https = this.injector.get(HttpClient)
        return https.get(`${env.baseApi}two-factor/generate-opt-default`)
    }

    disableTwoFactorDevice() {
        const https = this.injector.get(HttpClient)
        return https.put(`${env.baseApi}two-factor/disable-two-factor-device`, {})
    }

    validateOtp(status: string, authCode: string): Observable<AuthResponse> {
        const https = this.injector.get(HttpClient)
        return https.post<AuthResponse>(`${env.baseApi}two-factor/validate-otp/${status}`, {}, {headers: {'x-otp-code': authCode}})
    }
    validateAdminOtp(status: string, authCode: string, sw: number): Observable<AuthResponse> {
        const https = this.injector.get(HttpClient)
        if (sw === 0) {
            return https.post<AuthResponse>(`${env.baseApi}two-factor/admin/validate-otp/${status}`, {}, {headers: {'x-otp-code': authCode}})
        } else {
            return https.post<AuthResponse>(`${env.baseApi}two-factor/admin/validate-otp/${status}`, {}, {headers: {'x-otp-code': authCode}})
        }
    }
    setDefault2FA(type: string) {
        const https = this.injector.get(HttpClient)
        return https.put(`${env.baseApi}two-factor/set-default/${type}`, {})
    }

    getInfo(): Observable<IResponse<AuthInfoModel>> {
        const https = this.injector.get(HttpClient)
        return https.get<IResponse<AuthInfoModel>>(`${env.baseApi}teams/profile`)
    }

    recovery(email: EmailModel): Observable<IResponseOk> {
        const https = this.injector.get(HttpClient)
        return https.post<IResponseOk>(`${env.baseApi}teams/recovery`, email)
    }

    sendOtp(email: EmailModel): Observable<SendOtpModel> {
        const https = this.injector.get(HttpClient)
        return https.post<SendOtpEntity>(`${env.baseApi}teams/send-otp-login`, email).pipe(map(this.mapper.mapFrom))
    }

    loginOtp(auth: AuthOtpModel): Observable<AuthResponse> {
        const https = this.injector.get(HttpClient)
        return https.post<AuthResponse>(`${env.baseApi}teams/validate-otp-login`, auth)
    }

    generateNewOtpPublic(digits: number = 4, type: string, tokens: string): Observable<any> {
        const https = this.injector.get(HttpClient)
        return https.get(`${env.baseApi}two-factor/generate-opt-default`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokens}`,
            }),
        })
    }

    changePassword(change: ChangePasswordModel, key: string, value: string): Observable<IResponseOk> {
        const https = this.injector.get(HttpClient)
        return https.put<IResponseOk>(
            `${env.baseApi}teams/update/recovery-password`,
            {password: change.password},
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${change.token}`,
                    [key]: value,
                }),
            },
        )
    }
}
