import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {catchError, Observable, retry, throwError} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../../domain/base/response'
import {environment as env} from '../../../environments/environment'
import {CompanyBankRepository} from 'src/app/domain/repositories/company-bank.repository'
import {CompanyBankModel, CompanyBankSaveModel, CompanyBankTableModel, TransactionModel} from 'src/app/domain/model/company-bank.model'

@Injectable({
    providedIn: 'root',
})
export class CompanyBankImpRepository extends CompanyBankRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    handleError(error: any) {
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }

        return throwError(() => {
            return errorMessage
        })
    }

    getAll(): Observable<IResponsePaginate<CompanyBankModel[]>> {
        return this.http.get<IResponsePaginate<CompanyBankModel[]>>(`${this.api}companies/bank`).pipe(retry(1), catchError(this.handleError))
    }

    paginate(paginate: IPaginate): Observable<IResponsePaginate<CompanyBankTableModel[]>> {
        return this.http
            .get<IResponsePaginate<CompanyBankTableModel[]>>(
                `${this.api}company-bank/all?fieldSearch=${paginate.fieldSearch}&search=${paginate.search}&page=${paginate.page}&pageSize=${paginate.pageSize}`,
            )
            .pipe(retry(1), catchError(this.handleError))
    }

    header(companyBankId: number | null): Observable<IResponse<any>> {
        const param = companyBankId ? `get-account-Info/${companyBankId}` : 'get-general-Info'
        return this.http.get<IResponse<any>>(`${this.api}company-bank/${param}`)
    }

    save(formData: FormData, key: string, value: string): Observable<IResponse<CompanyBankSaveModel>> {
        return this.http.post<IResponse<CompanyBankSaveModel>>(`${this.api}company-bank`, formData, {headers: {[key]: value}})
    }

    update(formData: FormData, companyBankId: number | null, key: string, value: string): Observable<IResponse<CompanyBankSaveModel>> {
        return this.http.put<IResponse<CompanyBankSaveModel>>(`${this.api}company-bank/update/${companyBankId}`, formData, {headers: {[key]: value}})
    }

    delete(companyBankId: number | null, password: string): Observable<IResponse<CompanyBankSaveModel>> {
        return this.http.put<IResponse<CompanyBankSaveModel>>(`${this.api}company-bank/delete-account/${companyBankId}`, {password})
    }

    paginateTransaction(accountBankId: number): Observable<IResponsePaginate<TransactionModel[]>> {
        return this.http
            .get<IResponsePaginate<TransactionModel[]>>(`${this.api}company-bank/all-movements/${accountBankId}`)
            .pipe(retry(1), catchError(this.handleError))
    }

    getViewAccountNumber(accountBankId: number, key: string, value: string): Observable<IResponse<any>> {
        return this.http.get<IResponse<any>>(`${this.api}company-bank/get-account-number/${accountBankId}`, {headers: {[key]: value}})
    }
}
