import {Observable} from 'rxjs'
import {IPaginate, IResponse, IResponsePaginate} from '../base/response'
import {CompanyBankModel, CompanyBankSaveModel, CompanyBankTableModel, TransactionModel} from '../model/company-bank.model'

export abstract class CompanyBankRepository {
    abstract getAll(): Observable<IResponsePaginate<CompanyBankModel[]>>
    abstract paginate(paginate: IPaginate): Observable<IResponsePaginate<CompanyBankTableModel[]>>
    abstract header(companyBankId: number | null): Observable<IResponse<any>>
    abstract save(formData: FormData, key: string, value: string): Observable<IResponse<CompanyBankSaveModel>>
    abstract update(formData: FormData, companyBankId: number | null, key: string, value: string): Observable<IResponse<CompanyBankSaveModel>>
    abstract delete(companyBankId: number | null, password: string): Observable<IResponse<CompanyBankSaveModel>>
    abstract paginateTransaction(accountBankId: number): Observable<IResponsePaginate<TransactionModel[]>>
    abstract getViewAccountNumber(accountBankId: number | null, key: string, value: string): Observable<IResponse<any>>
}
