import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment as env} from '../../../environments/environment'
import {IResponse} from '../../domain/base/response'
import {CompanyPaymentSettingRepository} from 'src/app/domain/repositories/company-payment-setting.repository'
import {CompanyPaymentSettingModel} from 'src/app/domain/model/company-payment-setting.model'

@Injectable({
    providedIn: 'root',
})
export class CompanyPaymentSettingImpRepository extends CompanyPaymentSettingRepository {
    readonly api = env.baseApi

    constructor(private http: HttpClient) {
        super()
    }

    findByCompanyId(): Observable<IResponse<CompanyPaymentSettingModel>> {
        return this.http.get<IResponse<CompanyPaymentSettingModel>>(
            `${env.baseApi}company-payment-setting`,
        )
    }

    update(formData: FormData): Observable<IResponse<CompanyPaymentSettingModel>> {
        return this.http.put<IResponse<CompanyPaymentSettingModel>>(
            `${env.baseApi}company-payment-setting`,
            formData,
        )
    }
}
