import {Routes} from '@angular/router'

export const FullRoutes: Routes = [
    {
        path: 'login',
        loadComponent: () => import('../pages/login').then((m) => m.LoginComponent),
        data: {title: 'Admin - Login'},
    },
    {
        path: 'change-password',
        loadComponent: () => import('../pages/change-password').then((m) => m.ChangePasswordComponent),
        data: {title: 'Admin - Change Password'},
    },
    {
        path: '',
        loadChildren: () => import('../pages/error/error.module').then((m) => m.ErrorModule),
    },
    {
        path: 'change-password/:token',
        loadComponent: () => import('../pages/change-password').then((m) => m.ChangePasswordComponent),
        data: {title: 'Admin - Change Password'},
    },
    {
        path: 'recovery-password/:token',
        loadComponent: () => import('../pages/user/profile/recovery-password').then((m) => m.RecoveryPasswordComponent),
        data: {title: 'Admin - Recovery Password'},
    },
    {
        path: 'change-email/:token',
        loadComponent: () => import('../pages/user/profile/change-email').then((m) => m.ChangeEmailComponent),
        data: {title: 'Admin - Change Email'},
    },
    {
        path: 'verificación-carga-masiva',
        loadComponent: () => import('../pages/bulk-upload').then((m) => m.BulkUploadComponent),
        data: {title: 'Carga por Lote'},
    },
]
